import React from 'react';

const Solution = () => {
  return (
    <div>
      <section id="solution" class="py-12 lg:pt-36">
        <div class="mb-4 text-center">
          <span class="lg:text-3xl text-xl font-bold cbdc-text-blue">
            Solution
          </span>
        </div>
        <div class="flex justify-center">
          <div class="container pt-16 lg:flex">
            <div
              role="contentinfo"
              class="flex items-start flex-col pl-8 lg:w-1/4"
            >
              <p
                tabindex="0"
                class="title-font text-md uppercase text-blue-500 lg:mt-8 font-bold"
              >
                Everything you need
              </p>
              <h1
                tabindex="0"
                class="text-xl lg:text-4xl font-bold text-gray-200 pt-4"
              >
                All-in-one-platform
              </h1>
              <h1 tabindex="0" class="text-xl font-bold text-gray-500 pt-4">
                BSV Blockchain enabled solution
              </h1>
            </div>
            <div
              tabindex="0"
              aria-label="group of cards"
              class="lg:w-3/4 mt-8 flex flex-wrap justify-center gap-10"
            >
              <div
                tabindex="0"
                aria-label="card 4"
                class="px-4 pl-8 flex sm:w-full md:w-5/12 pb-16"
              >
                <div class="w-20 h-20 mr-2">
                  <img
                    src={'static/icons/CBDC.svg'}
                    class="h-12 w-12"
                    alt="CBDC enabled"
                  />
                </div>
                <div class="w-10/12">
                  <h2
                    tabindex="0"
                    class="text-xl font-semibold leading-tight text-gray-300"
                  >
                    CBDC Enabled
                  </h2>
                  <p
                    tabindex="0"
                    class="text-lg text-gray-400 leading-relaxed pt-2"
                  >
                    Tokenised cash backed by central bank money enables
                    automated atomic settlement of transactions allowing for
                    high-speed traffic along the whole value chain from trade to
                    settlement.
                  </p>
                </div>
              </div>

              <div
                tabindex="0"
                aria-label="card 4"
                class="px-4 pl-8 flex sm:w-full md:w-5/12 pb-16"
              >
                <div class="w-20 h-20 mr-2">
                  <img
                    src={'static/icons/highly_secure.svg'}
                    class="h-12 w-12"
                    alt="Highly Secure"
                  />
                </div>
                <div class="w-10/12">
                  <h2
                    tabindex="0"
                    class="text-xl font-semibold leading-tight text-gray-300"
                  >
                    Highly Secured
                  </h2>
                  <p
                    tabindex="0"
                    class="text-lg text-gray-400 leading-relaxed pt-2"
                  >
                    Distributed nodes ensure stability as there is no single
                    point of failure helping to manage operational resilience
                    and systemic risks.
                  </p>
                </div>
              </div>

              <div
                tabindex="0"
                aria-label="card 4"
                class="px-4 pl-8 flex sm:w-full md:w-5/12 pb-16"
              >
                <div class="w-20 h-20 mr-2">
                  <img
                    src={'static/icons/Liqudity_saved.svg'}
                    class="h-12 w-12"
                    alt="Liquidity saved"
                  />
                </div>
                <div class="w-10/12">
                  <h2
                    tabindex="0"
                    class="text-xl font-semibold leading-tight text-gray-300"
                  >
                    Liquidity Saved
                  </h2>
                  <p
                    tabindex="0"
                    class="text-lg text-gray-400 leading-relaxed pt-2"
                  >
                    Multi currencies allow for greater efficiencies in managing
                    fragmented liquidity.
                  </p>
                </div>
              </div>

              <div
                tabindex="0"
                aria-label="card 4"
                class="px-4 pl-8 flex sm:w-full md:w-5/12 pb-16"
              >
                <div class="w-20 h-20 mr-2">
                  <img
                    src={'static/icons/Instant_transfer.svg'}
                    class="h-12 w-12"
                    alt="Instant payments"
                  />
                </div>
                <div class="w-10/12">
                  <h2
                    tabindex="0"
                    class="text-xl font-semibold leading-tight text-gray-300"
                  >
                    Instant Payments
                  </h2>
                  <p
                    tabindex="0"
                    class="text-lg text-gray-400 leading-relaxed pt-2"
                  >
                    The peer-to-peer market enables immediate settlement and
                    interporates across multiple use cases.
                  </p>
                </div>
              </div>

              <div
                tabindex="0"
                aria-label="card 4"
                class="px-4 pl-8 flex sm:w-full md:w-5/12 pb-16"
              >
                <div class="w-20 h-20 mr-2">
                  <img
                    src={'static/icons/low_cost.svg'}
                    class="h-12 w-12"
                    alt="Low cost"
                  />
                </div>
                <div class="w-10/12">
                  <h2
                    tabindex="0"
                    class="text-xl font-semibold leading-tight text-gray-300"
                  >
                    Low Cost
                  </h2>
                  <p
                    tabindex="0"
                    class="text-lg text-gray-400 leading-relaxed pt-2"
                  >
                    Payment Vs Payment (PvP) enables real-time cross-border
                    payments reducing cost by eliminating intermediaries.
                  </p>
                </div>
              </div>

              <div
                tabindex="0"
                aria-label="card 4"
                class="px-4 pl-8 flex sm:w-full md:w-5/12 pb-16"
              >
                <div class="w-20 h-20 mr-2">
                  <img
                    src={'static/icons/Settlements.svg'}
                    class="h-12 w-12"
                    alt="settlement"
                  />
                </div>
                <div class="w-10/12">
                  <h2
                    tabindex="0"
                    class="text-xl font-semibold leading-tight text-gray-300"
                  >
                    Settlement Finality
                  </h2>
                  <p
                    tabindex="0"
                    class="text-lg text-gray-400 leading-relaxed pt-2"
                  >
                    Atomic Delivery Vs Payment (DvP) & Token Vs Token (TvT)
                    supports securities settlement and tokenised secondary
                    markets.
                  </p>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>
    </div>
  );
};

export default Solution;
