import React, { useState } from 'react';
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';

import { SendEmail } from '../SendEmail';
import { Header } from '../Header/Header';
import Footer from '../Footer';
import './Invest.css';

export const Invest = () => {
	const [title, setTitle] = useState('');
	const [email, setEmail] = useState('');
	const [company, setCompany] = useState('');
	const [mobile, setMobile] = useState('');
	const [message, setMessage] = useState('');

	const showSuccess = () => {
		toast.info('Successfully sent you request', {
			position: 'top-center',
			// autoClose: 5000,
			hideProgressBar: true,
			// closeOnClick: true,
			// pauseOnHover: true,
			// draggable: true,
			// progress: undefined,
			// theme: 'colored',
			theme: 'dark',
		});
	};
	const showError = () => {
		toast.error('Something went wrong', {
			position: 'top-center',
			// autoClose: 5000,
			hideProgressBar: true,
			// closeOnClick: true,
			// pauseOnHover: true,
			// draggable: true,
			// progress: undefined,
			// theme: 'colored',
			theme: 'dark',
		});
	};

	const sendEmail = new SendEmail();
	const sendInvest = async () => {
		try {
			await sendEmail.sendInvestForm(title, email, company, mobile, message);
			await sendEmail.InvestForm(title, email, company, mobile, message);
			showSuccess();
		} catch (error) {
			showError();
		}
	};

	const onSubmit = async (event) => {
		event.preventDefault();
		// alert('successfully sent you request');
		sendInvest();

		setTitle(' ');
		setEmail(' ');
		setCompany(' ');
		setMobile(' ');
		setMessage(' ');
	};
	return (
		<div>
			<Header />
			<ToastContainer />
			<section class="body-font pt-24">
				<p class="title-font text-center text-2xl uppercase cbdc-text-invest pt-4 py-4 font-bold">
					Investors Pitch
				</p>
				<div class="container mx-auto flex px-5 pt-12 pb-24 items-center gap-12 justify-center flex-col">
					<div class="mb-4">
						<iframe
							class="invest-video"
							src="https://www.youtube.com/embed/vzzusTfNrS8"
							title="YouTube video player"
							frameBorder="0"
							allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
							allowFullScreen
						></iframe>
					</div>
					<div class="lg:text-center lg:w-2/3 w-full">
						<h1 class="title-font text-white text-5xl mb-8 font-medium">
							Intrasettle start-up showcase at Block Dojo
						</h1>
						<p class="text-xl text-gray-300 mb-8 leading-relaxed">
							Intrasettle will be “a one-stop payment infrastructure that’s
							instant, highly secure, and cost-effective.” By hosting multiple
							CBDCs on a single platform, Intrasettle will help banks and
							financial institutions to make real-time settlements of
							cross-border payments. Multiple currencies also allow for greater
							efficiency in managing fragmented liquidity.
						</p>
					</div>
				</div>
				<div class="flex justify-center">
					<span class="inline-block h-1 w-20 rounded cbdc-bg-invest mt-4 mb-6"></span>
				</div>
			</section>
			<section class="body-font relative py-24">
				<div class="container px-5 py-24 mx-auto">
					<div class="flex flex-col text-center w-full mb-12">
						<h1 class="sm:text-3xl text-2xl font-medium title-font mb-4 text-white">
							Investment round open
						</h1>
						<p class="lg:w-2/3 mx-auto leading-relaxed text-base">
							If you would like to register your investment interest and receive
							more information from Intrasettle, please fill in your details
							below.
						</p>
					</div>
					<form onSubmit={onSubmit}>
						<div class="lg:w-3/4 md:w-1/3 mx-auto">
							<div class="flex flex-wrap -m-2">
								<div class="p-2 w-1/2">
									<div class="relative">
										<input
											type="text"
											required
											id="name"
											value={title}
											onChange={(e) => setTitle(e.target.value)}
											placeholder="Full name"
											name="name"
											class="w-full bg-gray-800 bg-opacity-40 rounded border border-gray-700 focus:border-blue-500 focus:bg-gray-900 focus:ring-2 focus:ring-blue-900 text-base outline-none text-gray-100 py-1 px-3 leading-8 transition-colors duration-200 ease-in-out"
										/>
									</div>
								</div>
								<div class="p-2 w-1/2">
									<div class="relative">
										<input
											type="email"
											required
											id="email"
											value={email}
											onChange={(e) => setEmail(e.target.value)}
											placeholder="Email"
											name="email"
											class="w-full bg-gray-800 bg-opacity-40 rounded border border-gray-700 focus:border-blue-500 focus:bg-gray-900 focus:ring-2 focus:ring-blue-900 text-base outline-none text-gray-100 py-1 px-3 leading-8 transition-colors duration-200 ease-in-out"
										/>
									</div>
								</div>
								<div class="p-2 w-1/2">
									<div class="relative">
										<input
											type="text"
											required
											id="company"
											value={company}
											onChange={(e) => setCompany(e.target.value)}
											name="company"
											placeholder="Company"
											class="w-full bg-gray-800 bg-opacity-40 rounded border border-gray-700 focus:border-blue-500 focus:bg-gray-900 focus:ring-2 focus:ring-blue-900 text-base outline-none text-gray-100 py-1 px-3 leading-8 transition-colors duration-200 ease-in-out"
										/>
									</div>
								</div>
								<div class="p-2 w-1/2">
									<div class="relative">
										<input
											type="number"
											required
											id="mobile"
											value={mobile}
											onChange={(e) => setMobile(e.target.value)}
											name="mobile"
											placeholder="Mobile"
											class="w-full bg-gray-800 bg-opacity-40 rounded border border-gray-700 focus:border-blue-500 focus:bg-gray-900 focus:ring-2 focus:ring-blue-900 text-base outline-none text-gray-100 py-1 px-3 leading-8 transition-colors duration-200 ease-in-out"
										/>
									</div>
								</div>
								<div class="p-2 w-full">
									<div class="relative">
										<textarea
											type="text"
											required
											id="message"
											value={message}
											onChange={(e) => setMessage(e.target.value)}
											name="message"
											placeholder="Message"
											class="w-full bg-gray-800 bg-opacity-40 rounded border border-gray-700 focus:border-blue-500 focus:bg-gray-900 focus:ring-2 focus:ring-blue-900 h-32 text-base outline-none text-gray-100 py-1 px-3 resize-none leading-6 transition-colors duration-200 ease-in-out"
										></textarea>
									</div>
								</div>
								<div class="p-2 w-full">
									<button class="flex mx-auto text-white bg-blue-500 border-0 py-2 px-8 focus:outline-none rounded text-lg">
										Submit
									</button>
								</div>
							</div>
						</div>
					</form>
				</div>
			</section>

			<section>
				<div class="max-w-3xl flex items-center justify-center h-auto lg:h-screen flex-wrap mx-auto my-32 lg:my-0">
					<div
						id="profile"
						class="w-full lg:w-3/5 rounded-lg lg:rounded-lg shadow-2xl bg-gray-300 mx-6 lg:mx-0"
					>
						<div class="p-4 md:p-12 text-center lg:text-left">
							<div class="rounded-full shadow-xl mx-auto my-4 h-48 w-48 bg-cover bg-center">
								<img
									src={'static/img/profile/Kumaraguru.png'}
									class="rounded-full"
									alt="Kumaraguru Ramanujam"
								/>
							</div>

							<h1 class="text-3xl text-gray-800 font-bold pt-8 lg:pt-0">
								Kumaraguru Ramanujam
							</h1>
							<p class="pt-4 text-base font-bold text-gray-800 flex items-center justify-center lg:justify-start">
								<svg
									class="h-4 fill-current cbdc-text-blue pr-4"
									xmlns="http://www.w3.org/2000/svg"
									viewBox="0 0 20 20"
								>
									<path d="M9 12H1v6a2 2 0 0 0 2 2h14a2 2 0 0 0 2-2v-6h-8v2H9v-2zm0-1H0V5c0-1.1.9-2 2-2h4V2a2 2 0 0 1 2-2h4a2 2 0 0 1 2 2v1h4a2 2 0 0 1 2 2v6h-9V9H9v2zm3-8V2H8v1h4z" />
								</svg>
								CEO
							</p>

							<p class="pt-8 text-gray-800 text-lg">
								Have more question? Book now to talk to our business manager.
							</p>

							<div class="pt-12 pb-8">
								<a
									target="_blank"
									rel="noreferrer"
									href="https://calendly.com/kumaragurur"
									class="cbdc-bg-blue text-white font-bold py-3 px-8 rounded-lg"
								>
									Book a meeting
								</a>
							</div>
						</div>
					</div>
				</div>
			</section>
			<Footer />
		</div>
	);
};
