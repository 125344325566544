import { HeaderMain } from './HeaderMain/HeaderMain';
import Footer from './Footer';
import { Hero } from './Home/Hero/Hero';
import Problem from './Home/Problem/Problem';
import Solution from './Home/Solution';
import CBDC from './Home/CBDC/CBDC';
import Steps from './Home/Steps';
import VisionMission from './Home/VisionMission';
import Team from './Home/Team/Team';
import Advisor from './Home/Advisor';
import Associates from './Home/Associates';
import { Events } from './Home/Events/Events';
import Demo from './Home/Demo';

export const Home = () => {
  return (
    <div>
      <HeaderMain />
      <Hero />
      <VisionMission />
      <Problem />
      <Solution />
      <CBDC />
      <Steps />
      <Team />
      <Advisor />
      <Associates />
      <Events />
      <Demo />
      <Footer />
    </div>
  );
};
