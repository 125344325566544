import { Routes, Route } from 'react-router-dom';
import { Home } from './components/Home';
import { Blogs } from './components/Blogs';
import { Blog } from './components/BlogPage/BlogPage';
import { Invest } from './components/Invest/Invest';
import { Contact } from './components/Contact/Contact';
import { CookieNotice } from './components/CookieNotice/CookieNotice';
import { PrivacyPolicy } from './components/PrivacyPolicy/PrivacyPolicy';

function App() {
  return (
    <Routes>
      <Route path="/" element={<Home />}></Route>
      <Route path="/blog" element={<Blogs />}></Route>
      <Route path="/blog/:slug" element={<Blog />}></Route>
      <Route path="/invest" element={<Invest />}></Route>
      <Route path="/contact" element={<Contact />}></Route>
      <Route path="/cookies" element={<CookieNotice />}></Route>
      <Route path="/privacypolicy" element={<PrivacyPolicy />}></Route>
    </Routes>
  );
}

export default App;
