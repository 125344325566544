import React from 'react';
import { Link } from 'react-router-dom';
import { FaXTwitter } from 'react-icons/fa6';
import { FaFacebookF } from 'react-icons/fa';
import { FaLinkedinIn } from 'react-icons/fa';
import { FaYoutube } from 'react-icons/fa';

const FooterBlog = () => {
	return (
		<div>
			<footer class="body-font border-t bg-black border-gray-800">
				<div class="py-8 text-center">
					<span class="text-lg font-bold text-blue-500">
						<Link to="/contact">contact us </Link>|
						<Link to="/privacypolicy"> privacy policy </Link>|
						<Link to="/cookies"> cookies </Link>
					</span>
				</div>
				<div class="container px-5 mx-auto flex justify-between items-center sm:flex-row flex-col">
					<div>
						<Link
							to="/"
							class="flex title-font font-medium items-center text-white mb-4 md:mb-0"
						>
							<img
								src={'https://intrasettle.com/static/img/intrasettle_white.png'}
								alt="intrasettle"
								class="h-14"
							></img>
						</Link>
					</div>
					<div>
						<p class="text-sm text-gray-400 sm:py-2 sm:mt-0 mt-4 text-center">
							Intrasettle Limited is registered in England and Wales. Company
							Number: 14087472, Plexal, Here East, London E20 3BS
						</p>
					</div>
					<div>
						<span class="inline-flex sm:ml-auto sm:mt-0 mt-4 justify-center sm:justify-start">
							{/* eslint-disable  */}

							<a
								target="_blank"
								rel="noreferrer"
								href="https://www.facebook.com/intrasettle"
								class="lg:ml-12 text-gray-400"
							>
								{/* <svg
									fill="currentColor"
									stroke-linecap="round"
									stroke-linejoin="round"
									stroke-width="2"
									class="w-5 h-5"
									viewBox="0 0 24 24"
								>
									<path d="M18 2h-3a5 5 0 00-5 5v3H7v4h3v8h4v-8h3l1-4h-4V7a1 1 0 011-1h3z"></path>
								</svg> */}
								<FaFacebookF />
							</a>
							<a
								target="_blank"
								rel="noreferrer"
								href="https://www.linkedin.com/company/intrasettle/"
								class="ml-3 text-gray-400"
							>
								{/* <svg
									fill="currentColor"
									stroke="currentColor"
									stroke-linecap="round"
									stroke-linejoin="round"
									stroke-width="0"
									class="w-5 h-5"
									viewBox="0 0 24 24"
								>
									<path
										stroke="none"
										d="M16 8a6 6 0 016 6v7h-4v-7a2 2 0 00-2-2 2 2 0 00-2 2v7h-4v-7a6 6 0 016-6zM2 9h4v12H2z"
									></path>
									<circle cx="4" cy="4" r="2" stroke="none"></circle>
								</svg> */}
								<FaLinkedinIn />
							</a>
							<a
								target="_blank"
								rel="noreferrer"
								href="https://twitter.com/intrasettle"
								class="ml-3 text-gray-400"
							>
								<FaXTwitter className="m-auto" />
								{/* <svg
									fill="currentColor"
									stroke-linecap="round"
									stroke-linejoin="round"
									stroke-width="2"
									class="w-5 h-5"
									viewBox="0 0 24 24"
								>
									<path d="M23 3a10.9 10.9 0 01-3.14 1.53 4.48 4.48 0 00-7.86 3v1A10.66 10.66 0 013 4s-4 9 5 13a11.64 11.64 0 01-7 2c9 5 20 0 20-11.5a4.5 4.5 0 00-.08-.83A7.72 7.72 0 0023 3z"></path>
								</svg> */}
							</a>
							<a
								target="_blank"
								rel="noreferrer"
								href="https://www.youtube.com/@intrasettle"
								class="ml-3 text-gray-400"
							>
								{/* <svg
									xmlns="http://www.w3.org/2000/svg"
									class="ionicon w-5 h-5"
									fill="currentColor"
									stroke="currentColor"
									stroke-linecap="round"
									stroke-linejoin="round"
									stroke-width="0"
									viewBox="0 0 512 512"
								>
									<title>Logo Youtube</title>
									<path d="M508.64 148.79c0-45-33.1-81.2-74-81.2C379.24 65 322.74 64 265 64h-18c-57.6 0-114.2 1-169.6 3.6C36.6 67.6 3.5 104 3.5 149 1 184.59-.06 220.19 0 255.79q-.15 53.4 3.4 106.9c0 45 33.1 81.5 73.9 81.5 58.2 2.7 117.9 3.9 178.6 3.8q91.2.3 178.6-3.8c40.9 0 74-36.5 74-81.5 2.4-35.7 3.5-71.3 3.4-107q.34-53.4-3.26-106.9zM207 353.89v-196.5l145 98.2z" />
								</svg> */}
								<FaYoutube />
							</a>
						</span>
					</div>
				</div>
				<div class="py-4 flex justify-center">
					<p class="text-sm text-gray-400">
						&#169; 2022 Intrasettle Limited. All rights Reserved.
					</p>
				</div>
			</footer>
		</div>
	);
};

export default FooterBlog;
