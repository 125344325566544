import React, { useState, useEffect } from 'react';
import axios from 'axios';
import { TailSpin } from 'react-loader-spinner';
import { Link } from 'react-router-dom';

function BlogPage() {
	const [blogPosts, setBlogPosts] = useState([]);
	const [loading, setLoading] = useState(true);
	const [currentPage, setCurrentPage] = useState(1);
	const postsPerPage = 8;

	useEffect(() => {
		const fetchData = async () => {
			try {
				const response = await axios.get(
					'https://intrasettle-server.web.app/blog'
				);
				const dataset = [];
				dataset.push(response.data.data);
				const sorted = dataset[0].data.sort(
					(a, b) => new Date(b.createdAt) - new Date(a.createdAt)
				);
				const extractedPosts = sorted.map(
					({ id, slug, category, date, title, description, cover }) => ({
						id,
						slug,
						category,
						date,
						title,
						description,
						cover,
					})
				);
				setBlogPosts(extractedPosts);
			} catch (error) {
				console.error(error);
			} finally {
				setLoading(false);
			}
		};
		fetchData();
	}, []); // eslint-disable-line react-hooks/exhaustive-deps

	// Get current posts
	const indexOfLastPost = currentPage * postsPerPage;
	const indexOfFirstPost = indexOfLastPost - postsPerPage;
	const currentPosts = blogPosts.slice(indexOfFirstPost, indexOfLastPost);

	// Change page
	const paginate = (pageNumber) => {
		setCurrentPage(pageNumber);
		window.scrollTo(0, 0); // Scroll to top of the page
	};
	// const handleShare = () => {
	//   const metaImage = document.createElement('meta');
	//   metaImage.setAttribute('property', 'og:image');
	//   metaImage.setAttribute('content', blog.cover);
	//   document.head.appendChild(metaImage);

	//   // Share the URL through WhatsApp
	//   // ...
	// };

	if (loading)
		return (
			<div className="flex justify-center items-center h-screen">
				<div className="spinner-container">
					<TailSpin
						height="80"
						width="80"
						color="#1177e1"
						ariaLabel="tail-spin-loading"
						radius="1"
						wrapperStyle={{}}
						wrapperClass=""
						visible={true}
					/>
				</div>
			</div>
		);

	return (
		<div>
			<section className="text-gray-400 bg-black body-font py-12">
				<div className="container px-5 py-24 mx-auto">
					{/* <div class="text-center">
            <span class="lg:text-5xl text-xl font-bold cbdc-text-blue ">
              Blogs
            </span>
          </div> */}
					<div className="flex flex-wrap -m-4 mt-8">
						{currentPosts.map((post) => (
							<div key={post.id} className="p-4 lg:w-1/4 md:w-1/3">
								<div className="h-full  border-gray-800 rounded-xl overflow-hidden blog-box">
									<Link
										// target="_blank"
										rel="noreferrer"
										to={`/blog/${post.slug}`}
									>
										<img
											className="lg:h-48 md:h-36 w-full object-cover object-center"
											src={post.cover}
											alt="blog"
										/>
									</Link>
									<div className="py-6">
										<h2 className="tracking-widest text-xs title-font font-medium  mb-1">
											<div className="flex items-center flex-wrap">
												<Link
													// target="_blank"
													rel="noreferrer"
													className="uppercase text-gray-500"
													to={`/blog/${post.slug}`}
												>
													{post.category}
												</Link>
												<span className="mr-3 inline-flex items-center text-gray-500 lg:ml-auto md:ml-0 ml-auto leading-none pr-3 py-1">
													{post.date}
												</span>
											</div>
										</h2>
										<h1 className="title-font text-lg font-medium text-white mb-3">
											{post.title}
										</h1>
										<p className="leading-relaxed mb-3">{post.description}</p>
										<div className="flex items-center flex-wrap">
											<Link
												// target="_blank"
												rel="noreferrer"
												to={`/blog/${post.slug}`}
												className="text-blue-400 inline-flex items-center md:mb-2 lg:mb-0"
											>
												Learn More
												<svg
													className="w-4 h-4 ml-2"
													viewBox="0 0 24 24"
													stroke="currentColor"
													stroke-width="2"
													fill="none"
													stroke-linecap="round"
													stroke-linejoin="round"
												>
													<path d="M5 12h14"></path>
													<path d="M12 5l7 7-7 7"></path>
												</svg>
											</Link>
										</div>
									</div>
								</div>
							</div>
						))}
					</div>
				</div>
			</section>

			{/* Pagination */}
			<div className="flex justify-center mb-12">
				{blogPosts.length > postsPerPage && (
					<ul className="flex">
						{Array.from({
							length: Math.ceil(blogPosts.length / postsPerPage),
						}).map((_, index) => (
							<li
								key={index}
								className={`mx-1 cursor-pointer relative inline-flex items-center px-4 py-2 text-sm font-semibold text-gray-100 ring-1 ring-inset ring-gray-300 hover:bg-blue-600 focus:z-20 focus:outline-offset-0 ${
									currentPage === index + 1
										? 'relative z-10 inline-flex items-center bg-blue-600 px-4 py-2 text-sm font-semibold text-white focus:z-20 focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-indigo-600'
										: ''
								}`}
								onClick={() => paginate(index + 1)}
							>
								{index + 1}
							</li>
						))}
					</ul>
				)}
			</div>
		</div>
	);
}

export default BlogPage;
