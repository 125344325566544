import React from 'react';
import './CBDC.css';

const CBDC = () => {
  return (
    <div>
      <section
        id="cross-border"
        class="py-12 px-4 flex justify-center lg:pt-32 lg:mt-8"
      >
        <div class="container flex flex-wrap md items-center">
          <div class="lg:w-1/2 lg:pr-16 lg:pl-8 px-4">
            <p class="title-font text-md uppercase lg:w-96 cbdc-text-green mb-4 font-bold">
              Accelerate your journey towards a seamless cross-border settlement
            </p>
            <h1 class="title-font text-5xl mb-12 font-medium">
              Intrasettle's CBDC
              <br />
              Cross-border Sandbox
            </h1>
            <p class="text-xl text-gray-300 mb-8 leading-relaxed">
              Simulate a multi CBDC model in a sandbox environment, enable
              functional testing of use cases from cross-border payments to
              securities settlement, move to a pilot, develop new projects and
              become production ready for real-world deployment.
            </p>
            <a href="#demo" class="">
              <button class="inline-flex show-modal items-center cbdc-bg-blue border-0 py-2 px-6 text-white focus:outline-none rounded text-base mt-4 md:mt-0">
                Request a demo
                <svg
                  fill="none"
                  stroke="currentColor"
                  stroke-linecap="round"
                  stroke-linejoin="round"
                  stroke-width="2"
                  class="w-4 h-4 ml-1"
                  viewBox="0 0 24 24"
                >
                  <path d="M5 12h14M12 5l7 7-7 7"></path>
                </svg>
              </button>
            </a>
          </div>
          <div class="w-full mt-8 lg:w-1/2 flex justify-center">
            <div class="img-tabs">
              <input
                class="img-item-input"
                id="img-one"
                checked
                name="img-tabs"
                type="radio"
                value="img-one"
              />
              <label class="img-item-label" for="img-one">
                Central bank
              </label>
              <input
                class="img-item-input"
                id="img-two"
                checked
                name="img-tabs"
                type="radio"
                value="img-two"
              />
              <label class="img-item-label" for="img-two">
                Wholesale Bank
              </label>
              <input
                class="img-item-input"
                id="img-three"
                checked
                name="img-tabs"
                type="radio"
                value="img-three"
              />
              <label class="img-item-label" for="img-three">
                CBDC Manager
              </label>
              <input
                class="img-item-input"
                id="img-four"
                checked
                name="img-tabs"
                type="radio"
                value="img-four"
              />
              <label class="img-item-label" for="img-four">
                Redeem
              </label>
              <section class="img-panels">
                <div class="img-panel">
                  <img
                    src={'static/img/bank-asset/central-bank.png'}
                    alt="Central-Bank-Dashboard"
                  />
                </div>
                <div class="img-panel">
                  <img
                    src={'static/img/bank-asset/wholesale-bank.png'}
                    alt="Wholesale-Bank-Dashboard"
                  />
                </div>
                <div class="img-panel">
                  <img
                    src={'static/img/bank-asset/CDBC-manger.png'}
                    alt="CBDC-Manager"
                  />
                </div>
                <div class="img-panel">
                  <img
                    src={'static/img/bank-asset/Redeem.png'}
                    alt="Redeem-Dashboard"
                  />
                </div>
              </section>
            </div>
          </div>
        </div>
      </section>
    </div>
  );
};

export default CBDC;
