const axios = require('axios');
class SendEmail {
	// Demo request form
	sendRequestForm(
		title,
		email,
		company,
		mobile,
		message,
		country,
		interestedIn
	) {
		const payload = {
			method: 'POST',
			headers: {
				'Content-Type': 'application/json',
			},
			body: JSON.stringify({
				name: title,
				email: email,
				company: company,
				mobile: mobile,
				message: message,
				country: country,
				interestedIn: interestedIn,
			}),
		};
		return fetch('https://intrasettle-server.web.app/server/mail', payload)
			.then((response) => response.json())
			.then((response) => {
				// alert("success");
				console.log('Success:', response);
			})
			.catch((error) => {
				console.log('Error:', error);
			});
	}

	async demoForm(
		title,
		email,
		company,
		mobile,
		message,
		country,
		interestedIn
	) {
		const payload = {
			method: 'POST',
			headers: {
				'Content-Type': 'application/json',
			},
			body: JSON.stringify({
				name: title,
				email: email,
				company: company,
				mobile: mobile,
				message: message,
				country: country,
				interestedIn: interestedIn,
			}),
		};
		try {
			const response = await fetch(
				'https://intrasettle-server.web.app/demo-form',
				payload
			);
			const response_1 = await response.json();
			// alert("success");
			console.log('Success:', response_1);
		} catch (error) {
			console.log('Error:', error);
		}
	}

	// Contact form
	sendContactForm(title, email, message) {
		const payload = {
			method: 'POST',
			headers: {
				'Content-Type': 'application/json',
			},
			body: JSON.stringify({
				name: title,
				email: email,
				message: message,
			}),
		};
		return fetch('https://intrasettle-server.web.app/server/contact', payload)
			.then((response) => response.json())
			.then((response) => {
				// alert("success");
				console.log('Success:', response);
			})
			.catch((error) => {
				console.log('Error:', error);
			});
	}

	async ContactForm(title, email, message) {
		const payload = {
			method: 'POST',
			headers: {
				'Content-Type': 'application/json',
			},
			body: JSON.stringify({
				name: title,
				email: email,
				message: message,
			}),
		};
		try {
			const response = await fetch(
				'https://intrasettle-server.web.app/contact-form',
				payload
			);
			const response_1 = await response.json();
			// alert("success");
			console.log('Success:', response_1);
		} catch (error) {
			console.log('Error:', error);
		}
	}

	// Invest enquiry form
	sendInvestForm(title, email, company, mobile, message) {
		const payload = {
			method: 'POST',
			headers: {
				'Content-Type': 'application/json',
			},
			body: JSON.stringify({
				name: title,
				email: email,
				company: company,
				mobile: mobile,
				message: message,
			}),
		};
		return fetch('https://intrasettle-server.web.app/server/invest', payload)
			.then((response) => response.json())
			.then((response) => {
				// alert("success");
				console.log('Success:', response);
			})
			.catch((error) => {
				console.log('Error:', error);
			});
	}

	async InvestForm(title, email, company, mobile, message) {
		const payload = {
			method: 'POST',
			headers: {
				'Content-Type': 'application/json',
			},
			body: JSON.stringify({
				name: title,
				email: email,
				company: company,
				mobile: mobile,
				message: message,
			}),
		};
		try {
			const response = await fetch(
				'https://intrasettle-server.web.app/invest-form',
				payload
			);
			const response_1 = await response.json();
			// alert("success");
			console.log('Success:', response_1);
		} catch (error) {
			console.log('Error:', error);
		}
	}

	// Subscriber form
	sendSubscriber(email) {
		const payload = {
			method: 'POST',
			headers: {
				'Content-Type': 'application/json',
			},
			body: JSON.stringify({
				email: email,
			}),
		};
		return fetch('https://intrasettle-server.web.app/server/subscribe', payload)
			.then((response) => response.json())
			.then((response) => {
				// alert("success");
				console.log('Success:', response);
			})
			.catch((error) => {
				console.log('Error:', error);
			});
	}

	// Send Subscriber to database

	// async subscriber(email) {
	// 	const payload = {
	// 		method: 'POST',
	// 		headers: {
	// 			'Content-Type': 'application/json',
	// 		},
	// 		body: JSON.stringify({
	// 			email: email,
	// 		}),
	// 	};
	// 	try {
	// 		const response = await fetch(
	// 			'https://intrasettle-server.web.app/subscriber',
	// 			payload
	// 		);
	// 		const response_1 = await response.json();
	// 		// alert("success");
	// 		console.log('Success:', response_1);
	// 	} catch (error) {
	// 		console.log('ErrorF:', error);
	// 		return error;
	// 	}
	// }

	async subscriber(email) {
		try {
			const response = await axios.post(
				'https://intrasettle-server.web.app/subscriber',
				{
					email: email,
				},
				{
					headers: {
						'Content-Type': 'application/json',
					},
				}
			);

			console.log('Success:', response.data);
			// alert("success");
		} catch (error) {
			console.log('Error:', error);
		}
	}
}

export { SendEmail };
