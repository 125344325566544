import React from 'react';

const Advisor = () => {
  return (
    <div>
      <section class="lg:pt-24 lg:mt-16">
        <div class="container px-6 py-10 mx-auto">
          <div class="mb-10 text-center">
            <span class="text-3xl font-bold cbdc-text-blue">Advisors</span>
          </div>

          <div class="grid grid-cols-1 gap-8 mt-8 xl:mt-16 md:grid-cols-3 lg:grid-cols-3">
            <div class="flex flex-col items-center p-8 transition-colors duration-200 transform cursor-pointer rounded-xl">
              <img
                src={'static/img/profile/alexander_ball.jpg'}
                alt="profile"
                className="object-cover w-40 h-40 rounded-full ring-2 ring-gray-300"
              />
              <div class="text-center mt-4">
                <div class="flex items-center gap-2 justify-center">
                  <p class="text-2xl">Alexander Ball</p>
                </div>
                <p class="text-lg text-gray-500 font-bold">
                  Barclays Techstars, Fintech ING
                </p>
                <p class="text-lg text-gray-500 font-bold">
                  Co-founder, Satoshi Block Dojo Ltd
                </p>
              </div>
              <div class="flex mt-3 -mx-2 text-gray-500">
                <a
                  target="_blank"
                  rel="noreferrer"
                  href="https://www.linkedin.com/in/alexanderball/"
                >
                  <svg
                    width="25"
                    height="25"
                    fill="currentColor"
                    class="text-xl text-gray-500 duration-200 hover:text-gray-700"
                    viewBox="0 0 1792 1792"
                    xmlns="http://www.w3.org/2000/svg"
                  >
                    <path d="M477 625v991h-330v-991h330zm21-306q1 73-50.5 122t-135.5 49h-2q-82 0-132-49t-50-122q0-74 51.5-122.5t134.5-48.5 133 48.5 51 122.5zm1166 729v568h-329v-530q0-105-40.5-164.5t-126.5-59.5q-63 0-105.5 34.5t-63.5 85.5q-11 30-11 81v553h-329q2-399 2-647t-1-296l-1-48h329v144h-2q20-32 41-56t56.5-52 87-43.5 114.5-15.5q171 0 275 113.5t104 332.5z"></path>
                  </svg>
                </a>
              </div>
              <div class="flex justify-center">
                <img
                  src={'static/flags/united-kingdom.png'}
                  class="h-12 w-12 mt-1"
                  alt="united-kingdom"
                />
              </div>
            </div>

            <div class="flex flex-col items-center p-8 transition-colors duration-200 transform cursor-pointer rounded-xl">
              <img
                src={'static/img/profile/nino.jpg'}
                alt="profile"
                className="object-cover w-40 h-40 rounded-full ring-2 ring-gray-300"
              />
              <div class="text-center mt-4">
                <div class="flex items-center gap-2 justify-center">
                  <p class="text-2xl">Antonino Sardegno</p>
                </div>
                <p class="text-lg text-gray-500 font-bold">
                  Managing Partner <br />
                  Hummingbird Capital Partners Ltd
                </p>
              </div>
              <div class="flex mt-3 -mx-2 text-gray-500">
                <a
                  target="_blank"
                  rel="noreferrer"
                  href="https://www.linkedin.com/in/antonino-nino/"
                >
                  <svg
                    width="25"
                    height="25"
                    fill="currentColor"
                    class="text-xl text-gray-500 duration-200 hover:text-gray-700"
                    viewBox="0 0 1792 1792"
                    xmlns="http://www.w3.org/2000/svg"
                  >
                    <path d="M477 625v991h-330v-991h330zm21-306q1 73-50.5 122t-135.5 49h-2q-82 0-132-49t-50-122q0-74 51.5-122.5t134.5-48.5 133 48.5 51 122.5zm1166 729v568h-329v-530q0-105-40.5-164.5t-126.5-59.5q-63 0-105.5 34.5t-63.5 85.5q-11 30-11 81v553h-329q2-399 2-647t-1-296l-1-48h329v144h-2q20-32 41-56t56.5-52 87-43.5 114.5-15.5q171 0 275 113.5t104 332.5z"></path>
                  </svg>
                </a>
              </div>
              <div class="flex justify-center">
                <img
                  src={'static/flags/switzerland.png'}
                  class="h-12 w-12 mt-1"
                  alt="switzerland"
                />
              </div>
            </div>

            <div class="flex flex-col items-center p-8 transition-colors duration-200 transform cursor-pointer rounded-xl">
              <img
                src={'static/img/profile/Mohammed_Ibrahim_Jega.jpg'}
                alt="profile"
                className="object-cover w-40 h-40 rounded-full ring-2 ring-gray-300"
              />
              <div class="text-center mt-4">
                <div class="flex items-center gap-2 justify-center">
                  <p class="text-2xl">Mohammad Ibrahim Jega</p>
                </div>
                <p class="text-lg text-gray-500 font-bold">
                  Co-founder <br />
                  Domineum Blockchain Solutions Ltd
                </p>
              </div>

              <div class="flex mt-3 -mx-2 text-gray-500">
                <a
                  target="_blank"
                  rel="noreferrer"
                  href="https://www.linkedin.com/in/mohammedibrahimjega/"
                >
                  <svg
                    width="25"
                    height="25"
                    fill="currentColor"
                    class="text-xl text-gray-500 duration-200 hover:text-gray-700"
                    viewBox="0 0 1792 1792"
                    xmlns="http://www.w3.org/2000/svg"
                  >
                    <path d="M477 625v991h-330v-991h330zm21-306q1 73-50.5 122t-135.5 49h-2q-82 0-132-49t-50-122q0-74 51.5-122.5t134.5-48.5 133 48.5 51 122.5zm1166 729v568h-329v-530q0-105-40.5-164.5t-126.5-59.5q-63 0-105.5 34.5t-63.5 85.5q-11 30-11 81v553h-329q2-399 2-647t-1-296l-1-48h329v144h-2q20-32 41-56t56.5-52 87-43.5 114.5-15.5q171 0 275 113.5t104 332.5z"></path>
                  </svg>
                </a>
              </div>
              <div class="flex justify-center">
                <img
                  src={'static/flags/nigeria.png'}
                  class="h-12 w-12 mt-1"
                  alt="nigeria"
                />
              </div>
            </div>
          </div>

          <div class="grid grid-cols-1 gap-8 mt-8 xl:mt-16 md:grid-cols-2 lg:grid-cols-2 lg:px-48">
            <div class="flex flex-col items-center p-8 transition-colors duration-200 transform cursor-pointer rounded-xl">
              <img
                src={'static/img/profile/prasanna-ad.jpg'}
                alt="profile"
                className="object-cover w-40 h-40 rounded-full ring-2 ring-gray-300"
              />
              <div class="text-center mt-4">
                <div class="flex items-center gap-2 justify-center">
                  <p class="text-2xl">Prasanna Lohar</p>
                </div>
                <p class="text-lg text-gray-500 font-bold">CEO, Block Stack</p>
                <p class="text-lg text-gray-500 font-bold">
                  Ex-Vice President Technology, DCB Bank
                </p>
              </div>

              <div class="flex mt-3 -mx-2 text-gray-500">
                <a
                  target="_blank"
                  rel="noreferrer"
                  href="https://www.linkedin.com/in/prasannalohar/"
                >
                  <svg
                    width="25"
                    height="25"
                    fill="currentColor"
                    class="text-xl text-gray-500 duration-200 hover:text-gray-700"
                    viewBox="0 0 1792 1792"
                    xmlns="http://www.w3.org/2000/svg"
                  >
                    <path d="M477 625v991h-330v-991h330zm21-306q1 73-50.5 122t-135.5 49h-2q-82 0-132-49t-50-122q0-74 51.5-122.5t134.5-48.5 133 48.5 51 122.5zm1166 729v568h-329v-530q0-105-40.5-164.5t-126.5-59.5q-63 0-105.5 34.5t-63.5 85.5q-11 30-11 81v553h-329q2-399 2-647t-1-296l-1-48h329v144h-2q20-32 41-56t56.5-52 87-43.5 114.5-15.5q171 0 275 113.5t104 332.5z"></path>
                  </svg>
                </a>
              </div>
              <div class="flex justify-center">
                <img
                  src={'static/flags/india.png'}
                  class="h-12 w-12 mt-1"
                  alt="india"
                />
              </div>
            </div>

            <div class="flex flex-col items-center p-8 transition-colors duration-200 transform cursor-pointer rounded-xl">
              <img
                src={'static/img/profile/sol_enenmoh.jpg'}
                alt="profile"
                className="object-cover w-40 h-40 rounded-full ring-2 ring-gray-300"
              />
              <div class="text-center mt-4">
                <div class="flex items-center gap-2 justify-center">
                  <p class="text-2xl">Sol Enenmoh</p>
                </div>
                <p class="text-lg text-gray-500 font-bold">
                  Director, Group Digitalisation
                  <br />
                  HSBC
                </p>
              </div>

              <div class="flex mt-3 -mx-2 text-gray-500">
                <a
                  target="_blank"
                  rel="noreferrer"
                  href="https://www.linkedin.com/in/digaleo/"
                >
                  <svg
                    width="25"
                    height="25"
                    fill="currentColor"
                    class="text-xl text-gray-500 duration-200 hover:text-gray-700"
                    viewBox="0 0 1792 1792"
                    xmlns="http://www.w3.org/2000/svg"
                  >
                    <path d="M477 625v991h-330v-991h330zm21-306q1 73-50.5 122t-135.5 49h-2q-82 0-132-49t-50-122q0-74 51.5-122.5t134.5-48.5 133 48.5 51 122.5zm1166 729v568h-329v-530q0-105-40.5-164.5t-126.5-59.5q-63 0-105.5 34.5t-63.5 85.5q-11 30-11 81v553h-329q2-399 2-647t-1-296l-1-48h329v144h-2q20-32 41-56t56.5-52 87-43.5 114.5-15.5q171 0 275 113.5t104 332.5z"></path>
                  </svg>
                </a>
              </div>
              <div class="flex justify-center">
                <img
                  src={'static/flags/united-kingdom.png'}
                  class="h-12 w-12 mt-1"
                  alt="united-kingdom"
                />
              </div>
            </div>
          </div>
        </div>
      </section>
    </div>
  );
};

export default Advisor;
