import React, { useState } from 'react';
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';

import { SendEmail } from '../SendEmail';
import { Header } from '../Header/Header';
import Footer from '../Footer';
import './Contact.css';

export const Contact = () => {
	const [title, setTitle] = useState('');
	const [email, setEmail] = useState('');
	const [message, setMessage] = useState('');

	const showSuccess = () => {
		toast.info('Successfully sent you request', {
			position: 'top-center',
			// autoClose: 5000,
			hideProgressBar: true,
			// closeOnClick: true,
			// pauseOnHover: true,
			// draggable: true,
			// progress: undefined,
			// theme: 'colored',
			theme: 'dark',
		});
	};
	const showError = () => {
		toast.error('Something went wrong', {
			position: 'top-center',
			// autoClose: 5000,
			hideProgressBar: true,
			// closeOnClick: true,
			// pauseOnHover: true,
			// draggable: true,
			// progress: undefined,
			// theme: 'colored',
			theme: 'dark',
		});
	};

	const sendEmail = new SendEmail();
	const sendContact = async () => {
		try {
			// Attempt to send the contact form
			await sendEmail.sendContactForm(title, email, message);
			await sendEmail.ContactForm(title, email, message);

			// If no errors, show success
			showSuccess();
		} catch (error) {
			// If an error occurs, show error message
			showError();
		}
	};

	const onSubmit = async (event) => {
		event.preventDefault();
		// alert('successfully sent you request');
		sendContact();

		setTitle(' ');
		setEmail(' ');
		setMessage(' ');
	};

	return (
		<div>
			<Header />
			<ToastContainer />
			<section class="contact-form body-font relative py-24">
				<div class="container px-5 py-24 mx-auto flex sm:flex-nowrap flex-wrap">
					<div class="lg:w-2/3 md:w-1/2 bg-gray-900 rounded-lg overflow-hidden sm:mr-10 p-10 flex items-end justify-start relative">
						<iframe
							width="100%"
							height="100%"
							title="map"
							class="section-contact-map absolute inset-0"
							frameBorder="0"
							marginheight="0"
							marginwidth="0"
							scrolling="no"
							src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d2481.1489209519873!2d-0.026196084414690967!3d51.54716821561595!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x48761d0d507317a9%3A0x945f42a338ca5b09!2sPlexal!5e0!3m2!1sen!2sin!4v1668957031569!5m2!1sen!2sin"
						></iframe>
						<div class="bg-gray-900 relative flex flex-wrap py-6 rounded shadow-md">
							<div class="lg:w-1/2 px-6">
								<h2 class="title-font font-semibold text-white tracking-widest text-xs">
									ADDRESS
								</h2>
								<p class="mt-1 text-gray-100">
									Plexal, Here East, <br />
									London E20 3BS.
								</p>
							</div>
							<div class="lg:w-1/2 px-6 mt-4 lg:mt-0">
								<h2 class="title-font font-semibold text-white tracking-widest text-xs">
									EMAIL
								</h2>
								<a
									class="text-blue-500 leading-relaxed"
									href="mailto:info@intrasettle.com"
								>
									info@intrasettle.com
								</a>
							</div>
						</div>
					</div>
					<div class="lg:w-1/3 md:w-1/2 flex flex-col md:ml-auto w-full md:py-8 mt-8 md:mt-0">
						<h2 class="text-white text-lg mb-1 font-medium title-font">
							Contact
						</h2>
						<form onSubmit={onSubmit} class="contact-form">
							<div class="relative mb-4">
								<input
									type="text"
									required
									id="name"
									value={title}
									onChange={(e) => setTitle(e.target.value)}
									placeholder="Full name"
									name="name"
									class="w-full bg-gray-800 rounded border border-gray-700 focus:border-blue-500 focus:ring-2 focus:ring-blue-900 text-base outline-none text-gray-100 py-1 px-3 leading-8 transition-colors duration-200 ease-in-out"
								/>
							</div>
							<div class="relative mb-4">
								<input
									type="email"
									required
									id="email"
									value={email}
									onChange={(e) => setEmail(e.target.value)}
									placeholder="Email"
									name="email"
									class="w-full bg-gray-800 rounded border border-gray-700 focus:border-blue-500 focus:ring-2 focus:ring-blue-900 text-base outline-none text-gray-100 py-1 px-3 leading-8 transition-colors duration-200 ease-in-out"
								/>
							</div>
							<div class="relative mb-4">
								<textarea
									id="message"
									required
									placeholder="Message"
									value={message}
									onChange={(e) => setMessage(e.target.value)}
									name="message"
									class="w-full bg-gray-800 rounded border border-gray-700 focus:border-blue-500 focus:ring-2 focus:ring-blue-900 h-32 text-base outline-none text-gray-100 py-1 px-3 resize-none leading-6 transition-colors duration-200 ease-in-out"
								></textarea>
							</div>
							<a href="/welcomeEmail">
								<button
									type="submit"
									value="submit"
									class="text-white bg-blue-500 border-0 py-2 px-6 focus:outline-none rounded text-lg"
								>
									submit
								</button>
							</a>
						</form>
					</div>
				</div>
			</section>
			<Footer />
		</div>
	);
};
