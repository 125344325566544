import React from 'react';

const Steps = () => {
  return (
    <div>
      <section class="pt-24 lg:py-12 px-4 flex justify-center lg:pt-32 lg:mt-8">
        <div class="container flex flex-wrap md items-center">
          <div class="lg:w-1/2 lg:pr-16 lg:pl-8 px-4">
            <div class="flex relative pb-36">
              <div class="h-full w-10 absolute inset-0 flex items-center justify-center">
                <div class="h-full w-0.5 bg-gray-800 pointer-events-none"></div>
              </div>
              <div class="flex-shrink-0 w-10 h-10 rounded-full text-blue-500 inline-flex items-center justify-center cbdc-bg-green relative z-10"></div>
              <div class="flex-grow pl-12">
                <h2 class="title-font cbdc-text-green text-2xl font-medium mb-1 tracking-wider">
                  Mint, Transfer, Redeem & Governance
                </h2>
                <p class="leading-relaxed text-lg text-gray-300">
                  Accelerate the issuance, management and governance of Central
                  Bank Digital Currency (CBDC) directly or hold pre-funded
                  settlement balances backed, 1 to 1, by central bank money.
                </p>
              </div>
            </div>
            <div class="flex relative pb-36">
              <div class="h-full w-10 absolute inset-0 flex items-center justify-center">
                <div class="h-full w-0.5 bg-gray-800 pointer-events-none"></div>
              </div>
              <div class="flex-shrink-0 w-10 h-10 rounded-full text-blue-500 inline-flex items-center justify-center cbdc-bg-green relative z-10"></div>
              <div class="flex-grow pl-12">
                <h2 class="title-font cbdc-text-green text-2xl font-medium mb-1 tracking-wider">
                  FX trade settlement
                </h2>
                <p class="leading-relaxed text-lg text-gray-300">
                  Program smart contract-based liquidity provisioning, market
                  making. Enable FX Matching, Atomic Payment vs Payment (PvP) &
                  Netting.
                </p>
              </div>
            </div>
            <div class="flex relative">
              <div class="flex-shrink-0 w-10 h-10 rounded-full text-blue-500 inline-flex items-center justify-center cbdc-bg-green relative z-10"></div>
              <div class="flex-grow pl-12">
                <h2 class="title-font cbdc-text-green text-2xl font-medium mb-1 tracking-wider">
                  Securities Settlement
                </h2>
                <p class="leading-relaxed text-lg text-gray-300">
                  Explore Atomic Devilery Vs Payment (DVP), Token Vs Account
                  (TvA) and Token Vs Token (TvT).
                </p>
              </div>
            </div>
          </div>
          <div class="w-full mt-8 lg:w-1/2 flex justify-center">
            <lottie-player
              src="https://assets9.lottiefiles.com/packages/lf20_viDlKsDsVL.json"
              background="transparent"
              speed="1"
              class="w-full h-full"
              loop
              autoplay
            ></lottie-player>
          </div>
        </div>
      </section>
    </div>
  );
};

export default Steps;
