import React from 'react';
import './Problem.css';

const Problem = () => {
  return (
    <div>
      <section class="py-24 lg:mt-48" id="problem">
        <div class="mb-20 lg:pt-12 text-center">
          <span class="text-3xl font-bold cbdc-text-blue">
            Problems with cross-border payments
          </span>
        </div>
        <div class="flex justify-center">
          <div class="main-content container">
            <div class="problem_wrapper">
              <div class="column">
                <div class="pb-12 py-24 border-l border-gray-500">
                  <div class="border-l-4 cbdc-border-blue">
                    <img
                      src={'static/icons/quotes.svg'}
                      class="ml-8 inline-block w-8 h-8 text-blue-500 mb-8"
                      alt="Quotation"
                    />
                    <p class="leading-normal italic ml-8 text-gray-300 text-base lg:text-xl">
                      "While numerous private sector players, from the CLS Group
                      to SWIFT, and central banks have initiated various
                      projects to resolve the existing pain points, we are yet
                      to see a scalable and seamless solution that can work
                      across countries, currencies, and payment systems"
                    </p>
                    <span class="ml-8 inline-block h-1 w-10 rounded cbdc-icon-blue mt-8 mb-6"></span>
                  </div>
                </div>

                <div class="pb-12 py-24 border-l border-gray-500">
                  <div class="border-l-4 cbdc-border-blue">
                    <img
                      src={'static/icons/quotes.svg'}
                      class="ml-8 inline-block w-8 h-8 mb-8"
                      alt="Quotation"
                    />
                    <p class="leading-normal italic ml-8 text-gray-300 text-base lg:text-xl">
                      “CBDCs, in my view, is the most efficient answer to
                      this... For example, if India and the US have CBDCs, we
                      don't have to wait for the banks to be open to settle
                      transactions. The central banks on either side need not be
                      open to get the finality of settlement. That massively
                      takes out the settlement of risk from cross-border
                      transactions. So, the internationalisation of CBDCs is
                      something I am looking forward to,”
                    </p>
                    <span class="ml-8 inline-block h-1 w-10 rounded cbdc-icon-blue mt-8 mb-6"></span>
                  </div>
                </div>

                <div class="py-24 border-l border-gray-500">
                  <div class="border-l-4 cbdc-border-blue">
                    <img
                      src={'static/icons/quotes.svg'}
                      class="ml-8 inline-block w-8 h-8 mb-8"
                      alt="Quotation"
                    />
                    <p class="leading-normal italic ml-8 text-gray-300 text-base lg:text-xl">
                      “While working on some a strategic acquisition
                      transactions with multiple banks involved in the funds
                      flow chain, our team was on high-alert with contradictory
                      inconsistent messages coming from numerous the banks along
                      the chain. While the current systems have been optimized
                      to handle day-to-day transactions, the non-regular,
                      high-value and time-pressured transactions are a real
                      problem area an area to improve.”
                    </p>
                    <span class="ml-8 inline-block h-1 w-10 rounded cbdc-icon-blue mt-8 mb-6"></span>
                  </div>
                </div>
              </div>

              <div class="image">
                <div class="lg:p-40 p-8">
                  <svg
                    xmlns="http://www.w3.org/2000/svg"
                    class="chart-pie"
                    viewBox="0 0 481.041 480.888"
                  >
                    <g id="problem-pie-chart" transform="translate(-599 -325)">
                      <g id="problem3" transform="translate(598.991 324.968)">
                        <path
                          class="opacity-80 hover:opacity-100 bg-opaque-rates"
                          id="Path_56"
                          data-name="Path 56"
                          d="M438,630.69c-6.33-1.55-12.74-2.81-19-4.69A240.18,240.18,0,0,1,257,461.62a249.354,249.354,0,0,1-7.6-38.13,223.459,223.459,0,0,1-1.4-39.11c2.26-44.1,15.36-84.74,39.85-121.6.19-.29.43-.55.65-.83,4.54,3.07,9.07,6.16,13.62,9.21l80.31,53.7q45.65,30.54,91.29,61.06c4.78,3.19,9.68,6.19,14.52,9.27l.16.55v1a10.426,10.426,0,0,0-.56,1.46q-8.55,39.88-17.08,79.77-9.65,45-19.26,90.09Q444.75,599.4,438,630.69Z"
                          transform="translate(-247.69 -155.25)"
                          fill="#218eb5"
                        />
                        <path
                          class="opacity-80 hover:opacity-100 bg-slow-payments"
                          id="Path_57"
                          data-name="Path 57"
                          d="M488.39,395.74l-.16-.55c0-1.17.08-2.34.08-3.52V155.32a202,202,0,0,1,37.41,2.76,229,229,0,0,1,71.19,23.06q94.47,49.47,123.65,152.13a224.16,224.16,0,0,1,8.18,60.27,17.325,17.325,0,0,1-.09,2.16H489.74C489.29,395.69,488.84,395.72,488.39,395.74Z"
                          transform="translate(-247.69 -155.25)"
                          fill="#60acca"
                        />
                        <path
                          class="opacity-80 hover:opacity-100 bg-high-fee"
                          id="Path_58"
                          data-name="Path 58"
                          d="M488.33,155.32V391.67c0,1.18-.05,2.35-.08,3.52-4.84-3.08-9.74-6.08-14.52-9.27q-45.68-30.5-91.29-61.06l-80.31-53.7c-4.55-3.05-9.08-6.14-13.62-9.21a226.557,226.557,0,0,1,34.2-40.65,238.38,238.38,0,0,1,106.15-58.69,217,217,0,0,1,44.09-6.85C478.06,155.53,483.19,155.46,488.33,155.32Z"
                          transform="translate(-247.69 -155.25)"
                          fill="#52cfc4"
                        />
                        <path
                          class="opacity-80 hover:opacity-100 bg-compliance"
                          id="Path_59"
                          data-name="Path 59"
                          d="M438,630.69q6.74-31.31,13.47-62.6,9.65-45,19.26-90.09,8.52-39.88,17.08-79.77a10.428,10.428,0,0,1,.56-1.46,11.719,11.719,0,0,1,2.18,1.43c2.69,2.77,5.3,5.63,8,8.45l26.29,27.92q8.51,9,17,18.1l26.48,28.11q8.41,8.94,16.83,17.9,13.23,14.07,26.49,28.11c7.8,8.24,15.69,16.4,23.49,24.64,3.58,3.79,6.94,7.8,10.57,11.53,2.48,2.55,5.28,4.79,7.93,7.17a218,218,0,0,1-36.74,28.78,237,237,0,0,1-91.27,34.37,242.291,242.291,0,0,1-86.33-2.12A8.431,8.431,0,0,1,438,630.69Z"
                          transform="translate(-247.69 -155.25)"
                          fill="#c6e1ea"
                        />
                        <path
                          class="opacity-80 hover:opacity-100 bg-middleman"
                          id="Path_60"
                          data-name="Path 60"
                          d="M653.59,570.13c-2.65-2.38-5.45-4.62-7.93-7.17-3.63-3.73-7-7.74-10.57-11.53-7.8-8.24-15.69-16.4-23.49-24.64q-13.29-14-26.49-28.11-8.43-8.94-16.83-17.9L541.8,452.67q-8.52-9-17-18.1l-26.29-27.92c-2.66-2.82-5.27-5.68-8-8.45a11.721,11.721,0,0,0-2.18-1.43c0-.34,0-.68,0-1,.45,0,.9-.05,1.35-.05H728.67a229,229,0,0,1-5.74,52.57c-10.68,47.27-33.71,87.57-68.48,121.23a7.6,7.6,0,0,1-.86.61Z"
                          transform="translate(-247.69 -155.25)"
                          fill="#f9f9f9"
                        />
                      </g>
                      <g class="svg-opaque-rates">
                        <path
                          id="Path_6"
                          data-name="Path 6"
                          d="M160.422,275.609H126.61v42.307h33.812Z"
                          transform="translate(588 295)"
                          fill="none"
                          stroke="#fff"
                          stroke-linecap="round"
                          stroke-linejoin="round"
                          stroke-width="1.219"
                        />
                        <path
                          id="Path_7"
                          data-name="Path 7"
                          d="M157.476,278.8H129.434v7.4h28.042Z"
                          transform="translate(588 295)"
                          fill="none"
                          stroke="#fff"
                          stroke-linecap="round"
                          stroke-linejoin="round"
                          stroke-width="0.61"
                        />
                        <path
                          id="Path_8"
                          data-name="Path 8"
                          d="M129.15,288.8H157.8"
                          transform="translate(588 295)"
                          fill="none"
                          stroke="#fff"
                          stroke-linecap="round"
                          stroke-linejoin="round"
                          stroke-width="0.61"
                        />
                        <path
                          id="Path_9"
                          data-name="Path 9"
                          d="M129.15,302.7H157.8"
                          transform="translate(588 295)"
                          fill="none"
                          stroke="#fff"
                          stroke-linecap="round"
                          stroke-linejoin="round"
                          stroke-width="0.61"
                        />
                        <path
                          id="Path_10"
                          data-name="Path 10"
                          d="M129.15,295.747H157.8"
                          transform="translate(588 295)"
                          fill="none"
                          stroke="#fff"
                          stroke-linecap="round"
                          stroke-linejoin="round"
                          stroke-width="0.61"
                        />
                        <path
                          id="Path_11"
                          data-name="Path 11"
                          d="M129.15,309.646H157.8"
                          transform="translate(588 295)"
                          fill="none"
                          stroke="#fff"
                          stroke-linecap="round"
                          stroke-linejoin="round"
                          stroke-width="0.61"
                        />
                        <path
                          id="Path_12"
                          data-name="Path 12"
                          d="M129.15,291.114H157.8"
                          transform="translate(588 295)"
                          fill="none"
                          stroke="#fff"
                          stroke-linecap="round"
                          stroke-linejoin="round"
                          stroke-width="0.61"
                        />
                        <path
                          id="Path_13"
                          data-name="Path 13"
                          d="M129.15,305.013H157.8"
                          transform="translate(588 295)"
                          fill="none"
                          stroke="#fff"
                          stroke-linecap="round"
                          stroke-linejoin="round"
                          stroke-width="0.61"
                        />
                        <path
                          id="Path_14"
                          data-name="Path 14"
                          d="M129.15,298.063H157.8"
                          transform="translate(588 295)"
                          fill="none"
                          stroke="#fff"
                          stroke-linecap="round"
                          stroke-linejoin="round"
                          stroke-width="0.61"
                        />
                        <path
                          id="Path_15"
                          data-name="Path 15"
                          d="M129.15,311.962H157.8"
                          transform="translate(588 295)"
                          fill="none"
                          stroke="#fff"
                          stroke-linecap="round"
                          stroke-linejoin="round"
                          stroke-width="0.61"
                        />
                        <path
                          id="Path_16"
                          data-name="Path 16"
                          d="M129.15,293.43H157.8"
                          transform="translate(588 295)"
                          fill="none"
                          stroke="#fff"
                          stroke-linecap="round"
                          stroke-linejoin="round"
                          stroke-width="0.61"
                        />
                        <path
                          id="Path_17"
                          data-name="Path 17"
                          d="M129.15,307.329H157.8"
                          transform="translate(588 295)"
                          fill="none"
                          stroke="#fff"
                          stroke-linecap="round"
                          stroke-linejoin="round"
                          stroke-width="0.61"
                        />
                        <path
                          id="Path_18"
                          data-name="Path 18"
                          d="M129.15,300.38H157.8"
                          transform="translate(588 295)"
                          fill="none"
                          stroke="#fff"
                          stroke-linecap="round"
                          stroke-linejoin="round"
                          stroke-width="0.61"
                        />
                        <path
                          id="Path_19"
                          data-name="Path 19"
                          d="M129.15,314.279H157.8"
                          transform="translate(588 295)"
                          fill="none"
                          stroke="#fff"
                          stroke-linecap="round"
                          stroke-linejoin="round"
                          stroke-width="0.61"
                        />
                        <path
                          id="Path_20"
                          data-name="Path 20"
                          d="M149.608,302.75a2.235,2.235,0,0,0-3.322,2.991l13.9,15.433a2.235,2.235,0,1,0,3.322-2.991Z"
                          transform="translate(588 295)"
                          fill="#3a3a3a"
                        />
                        <path
                          id="Path_21"
                          data-name="Path 21"
                          d="M149.382,302.954a1.93,1.93,0,0,0-2.869,2.583l13.9,15.434a1.931,1.931,0,0,0,2.869-2.584Z"
                          transform="translate(588 295)"
                          fill="none"
                          stroke="#fff"
                          stroke-linecap="round"
                          stroke-linejoin="round"
                          stroke-width="0.61"
                        />
                        <path
                          id="Path_22"
                          data-name="Path 22"
                          d="M143.028,310.885a11.867,11.867,0,1,0-11.867-11.867A11.867,11.867,0,0,0,143.028,310.885Z"
                          transform="translate(588 295)"
                          fill="#3a3a3a"
                        />
                        <path
                          id="Path_23"
                          data-name="Path 23"
                          d="M143.028,310.479a11.46,11.46,0,1,0-11.46-11.461A11.461,11.461,0,0,0,143.028,310.479Z"
                          transform="translate(588 295)"
                          fill="none"
                          stroke="#fff"
                          stroke-linecap="round"
                          stroke-linejoin="round"
                          stroke-width="0.813"
                        />
                        <path
                          id="Path_24"
                          data-name="Path 24"
                          d="M143.028,308.813a9.794,9.794,0,1,0-9.794-9.795A9.8,9.8,0,0,0,143.028,308.813Z"
                          transform="translate(588 295)"
                          fill="#3a3a3a"
                        />
                        <path
                          id="Path_25"
                          data-name="Path 25"
                          d="M143.028,308.508a9.49,9.49,0,1,0-9.489-9.49A9.49,9.49,0,0,0,143.028,308.508Z"
                          transform="translate(588 295)"
                          fill="none"
                          stroke="#fff"
                          stroke-linecap="round"
                          stroke-linejoin="round"
                          stroke-width="0.61"
                        />
                        <path
                          id="Path_26"
                          data-name="Path 26"
                          d="M141.663,301.953v-.2a6.093,6.093,0,0,1,.179-1.611,2.423,2.423,0,0,1,.519-.969,3.939,3.939,0,0,1,.82-.67,5.6,5.6,0,0,0,.641-.479,2.089,2.089,0,0,0,.45-.549,1.4,1.4,0,0,0,.167-.681,1.252,1.252,0,0,0-.7-1.16,1.526,1.526,0,0,0-.7-.162,1.562,1.562,0,0,0-.71.168,1.347,1.347,0,0,0-.549.485,1.491,1.491,0,0,0-.231.79h-2.355a3.4,3.4,0,0,1,.554-1.9,3.171,3.171,0,0,1,1.391-1.125,4.763,4.763,0,0,1,1.911-.375,5.09,5.09,0,0,1,2.032.381,3.146,3.146,0,0,1,1.386,1.091,2.926,2.926,0,0,1,.5,1.726,3.021,3.021,0,0,1-.225,1.2,2.905,2.905,0,0,1-.618.924,4.765,4.765,0,0,1-.941.722,3.819,3.819,0,0,0-.75.565,1.92,1.92,0,0,0-.433.682,2.915,2.915,0,0,0-.145.952v.2Zm1.143,3.7a1.36,1.36,0,0,1-.988-.4,1.33,1.33,0,0,1-.4-.982,1.3,1.3,0,0,1,.4-.969,1.356,1.356,0,0,1,.988-.4,1.339,1.339,0,0,1,.964.4,1.323,1.323,0,0,1,.421.969,1.345,1.345,0,0,1-.2.7,1.488,1.488,0,0,1-.5.5A1.314,1.314,0,0,1,142.806,305.648Z"
                          transform="translate(588 295)"
                          fill="#fff"
                        />
                      </g>
                      <g class="svg-slow-payments">
                        <path
                          id="Path_29"
                          data-name="Path 29"
                          d="M365.556,130.078h1.207a20.349,20.349,0,0,1,20.349,20.349v1.767a1.077,1.077,0,0,1-1.078,1.078H346.155a1.075,1.075,0,0,1-1.077-1.078v-1.638a20.478,20.478,0,0,1,20.478-20.478Z"
                          transform="translate(572 295)"
                          fill="none"
                          stroke="#fff"
                          stroke-linecap="round"
                          stroke-linejoin="round"
                          stroke-width="2.156"
                        />
                        <path
                          id="Path_30"
                          data-name="Path 30"
                          d="M346.8,132.026a1.854,1.854,0,0,1,2.624,0l18.991,18.991-2.531,2.53L346.8,134.462a1.721,1.721,0,0,1,0-2.436Z"
                          transform="translate(572 295)"
                          fill="#3a3a3a"
                        />
                        <path
                          id="Path_31"
                          data-name="Path 31"
                          d="M366.634,157.8a5.691,5.691,0,1,0-5.691-5.691A5.691,5.691,0,0,0,366.634,157.8Z"
                          transform="translate(572 295)"
                          fill="#3a3a3a"
                        />
                      </g>
                      <g class="svg-compliance">
                        <path
                          id="Path_33"
                          data-name="Path 33"
                          d="M406.044,301.348h28.009a.679.679,0,0,1,.678.678V312.59a3.2,3.2,0,0,1-3.2,3.2H408.854a3.2,3.2,0,0,1-3.2-3.2V301.736a.387.387,0,0,1,.388-.388Z"
                          transform="translate(566.088 295)"
                          fill="none"
                          stroke="#00ceae"
                          stroke-linecap="round"
                          stroke-linejoin="round"
                          stroke-width="2.907"
                        />
                        <path
                          id="Path_34"
                          data-name="Path 34"
                          d="M419.612,295.436a5.718,5.718,0,1,0-5.718-5.718A5.718,5.718,0,0,0,419.612,295.436Z"
                          transform="translate(566.088 295)"
                          fill="#3a3a3a"
                        />
                        <path
                          id="Path_35"
                          data-name="Path 35"
                          d="M405.269,293.885a3.683,3.683,0,1,0-3.683-3.682A3.682,3.682,0,0,0,405.269,293.885Z"
                          transform="translate(566.088 295)"
                          fill="#00ceae"
                        />
                        <path
                          id="Path_36"
                          data-name="Path 36"
                          d="M434.247,293.885a3.683,3.683,0,1,0-3.683-3.682A3.682,3.682,0,0,0,434.247,293.885Z"
                          transform="translate(566.088 295)"
                          fill="#00ceae"
                        />
                        <path
                          id="Path_37"
                          data-name="Path 37"
                          d="M403.573,295.533h3.392a6.057,6.057,0,0,1,6.057,6.057,1.212,1.212,0,0,1-1.211,1.212H399.454A1.454,1.454,0,0,1,398,301.348v-.242a5.572,5.572,0,0,1,5.573-5.573Z"
                          transform="translate(566.088 295)"
                          fill="#00ceae"
                        />
                        <path
                          id="Path_38"
                          data-name="Path 38"
                          d="M432.551,295.533h3.392A6.057,6.057,0,0,1,442,301.59a1.212,1.212,0,0,1-1.211,1.212H428.432a1.454,1.454,0,0,1-1.454-1.454v-.242a5.572,5.572,0,0,1,5.573-5.573Z"
                          transform="translate(566.088 295)"
                          fill="#00ceae"
                        />
                        <path
                          id="Path_39"
                          data-name="Path 39"
                          d="M424.361,311.718h-9.11v8.529h9.11Z"
                          transform="translate(566.088 295)"
                          fill="#3a3a3a"
                        />
                        <path
                          id="Path_40"
                          data-name="Path 40"
                          d="M416.511,298.828H423.2a7.268,7.268,0,0,1,7.269,7.269v.581a1.454,1.454,0,0,1-1.454,1.454H411.568a1.745,1.745,0,0,1-1.744-1.745v-.872a6.687,6.687,0,0,1,6.687-6.687Z"
                          transform="translate(566.088 295)"
                          fill="#3a3a3a"
                        />
                        <path
                          id="Path_41"
                          data-name="Path 41"
                          d="M421.163,308.132h-2.617v3.586h2.617Z"
                          transform="translate(566.088 295)"
                          fill="#3a3a3a"
                        />
                      </g>
                      <g class="svg-middleman">
                        <path
                          id="Path_43"
                          data-name="Path 43"
                          d="M313.372,369.384l-13.666-4.709-2.6,7.53,13.666,4.71Z"
                          transform="translate(575.439 295)"
                          fill="#00ceae"
                        />
                        <path
                          id="Path_44"
                          data-name="Path 44"
                          d="M290.3,388.584h20.811a2.294,2.294,0,0,1,2.3,2.295V392.1H288v-1.224a2.294,2.294,0,0,1,2.3-2.295Z"
                          transform="translate(575.439 295)"
                          fill="#00ceae"
                        />
                        <path
                          id="Path_45"
                          data-name="Path 45"
                          d="M313.7,371.363l18.422,6.348a1.992,1.992,0,0,1,1.234,2.531l-.1.275a2.145,2.145,0,0,1-2.725,1.328l-18.278-6.3Z"
                          transform="translate(575.439 295)"
                          fill="#00ceae"
                        />
                        <path
                          id="Path_46"
                          data-name="Path 46"
                          d="M295.968,372.587l15.479,5.334-2.071,6.01a1.607,1.607,0,0,1-2.043,1l-12.512-4.312a1.531,1.531,0,0,1-.949-1.947Z"
                          transform="translate(575.439 295)"
                          fill="#00ceae"
                        />
                        <path
                          id="Path_47"
                          data-name="Path 47"
                          d="M294.323,378.541l14.755,5.085a1.258,1.258,0,0,1-1.6.78l-12.43-4.283a1.2,1.2,0,0,1-.744-1.525Z"
                          transform="translate(575.439 295)"
                          fill="#3a3a3a"
                        />
                        <path
                          id="Path_48"
                          data-name="Path 48"
                          d="M303.127,356.5l12.512,4.312a1.607,1.607,0,0,1,1,2.043l-2.071,6.011-15.479-5.335,2.1-6.082a1.531,1.531,0,0,1,1.946-.949Z"
                          transform="translate(575.439 295)"
                          fill="#00ceae"
                        />
                        <path
                          id="Path_49"
                          data-name="Path 49"
                          d="M302.965,357.051l12.464,4.3a1.17,1.17,0,0,1,.725,1.486L301.548,357.8l.024-.069a1.1,1.1,0,0,1,1.393-.679Z"
                          transform="translate(575.439 295)"
                          fill="#3a3a3a"
                        />
                      </g>
                      <g class="svg-high-fee">
                        <path
                          id="Path_50"
                          data-name="Path 50"
                          d="M189.531,112.105l-1.473-1.524L181,117.4l1.473,1.524Z"
                          transform="translate(575.31 295)"
                          fill="#fff"
                        />
                        <path
                          id="Path_51"
                          data-name="Path 51"
                          d="M202.882,105.525,201.1,104.37l-5.35,8.236,1.778,1.156Z"
                          transform="translate(575.31 295)"
                          fill="#fff"
                        />
                        <path
                          id="Path_52"
                          data-name="Path 52"
                          d="M216.532,95.092l-1.9-.929-7.335,15.04,1.9.929Z"
                          transform="translate(575.31 295)"
                          fill="#fff"
                        />
                        <path
                          id="Path_53"
                          data-name="Path 53"
                          d="M197.507,113.755l.223-2.109-9.768-1.03-.222,2.109Z"
                          transform="translate(575.31 295)"
                          fill="#fff"
                        />
                        <path
                          id="Path_54"
                          data-name="Path 54"
                          d="M209.224,110.173l.828-1.952-9.041-3.838-.829,1.952Z"
                          transform="translate(575.31 295)"
                          fill="#fff"
                        />
                        <path
                          id="Path_55"
                          data-name="Path 55"
                          d="M218.782,88.659l.812,11.306-10.437-5.318Z"
                          transform="translate(575.31 295)"
                          fill="#fff"
                        />
                      </g>
                      <text
                        class="text-opaque-rates"
                        id="opaque_conversion_rates"
                        data-name="opaque conversion rates"
                        transform="translate(731 637)"
                        font-size="16"
                        font-family="SegoeUI-Bold, Segoe UI"
                        font-weight="700"
                      >
                        <tspan x="-28.199" y="0">
                          opaque
                        </tspan>
                        <tspan x="-40.91" y="21">
                          conversion
                        </tspan>
                        <tspan x="-18.418" y="42">
                          rates
                        </tspan>
                      </text>
                      <text
                        class="text-compliance"
                        id="Burdensome_regulatory_compliance"
                        data-name="Burdensome regulatory &amp; compliance"
                        transform="translate(889 711)"
                        font-size="16"
                        font-family="SegoeUI-Bold, Segoe UI"
                        font-weight="700"
                      >
                        <tspan x="-47.309" y="0">
                          Burdensome
                        </tspan>
                        <tspan x="-39.633" y="21">
                          regulatory
                        </tspan>
                        <tspan x="-51.883" y="42">
                          &amp; compliance
                        </tspan>
                      </text>
                      <text
                        class="text-middleman"
                        id="Too_many_middleman"
                        data-name="Too many middleman"
                        transform="translate(990 637)"
                        font-size="16"
                        font-family="SegoeUI-Bold, Segoe UI"
                        font-weight="700"
                      >
                        <tspan x="-36.734" y="0">
                          Too many
                        </tspan>
                        <tspan x="-42.582" y="21">
                          middleman
                        </tspan>
                      </text>
                      <text
                        class="text-high-fee"
                        id="High_fee"
                        data-name="High fee"
                        transform="translate(770 440)"
                        font-size="16"
                        font-family="SegoeUI-Bold, Segoe UI"
                        font-weight="700"
                      >
                        <tspan x="-18.172" y="0">
                          High
                        </tspan>
                        <tspan x="-11.723" y="21">
                          fee
                        </tspan>
                      </text>
                      <text
                        class="text-slow-payments"
                        id="Slow_Payments"
                        data-name="Slow Payments"
                        transform="translate(939 478)"
                        font-size="16"
                        font-family="SegoeUI-Bold, Segoe UI"
                        font-weight="700"
                      >
                        <tspan x="-18.027" y="0">
                          Slow
                        </tspan>
                        <tspan x="-36.453" y="21">
                          Payments
                        </tspan>
                      </text>
                    </g>
                  </svg>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>
    </div>
  );
};

export default Problem;
