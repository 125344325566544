import React, { useEffect, useState } from 'react';
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';

import GlobeComponent from '../GlobeComponent/GlobeComponent';
import HeroData from './hero.json';
import './Hero.css';
import { SendEmail } from '../../SendEmail';

const Hero = () => {
	const [email, setEmail] = useState('');

	useEffect(() => {
		const content = document.querySelector('.content-inner');
		const contentFull = document.querySelector('.content-full');
		const more = document.querySelector('.read-more');
		let open = false;

		if (more) {
			more.addEventListener('click', (e) => {
				if (open) {
					content.removeAttribute('style');
					e.target.innerText = 'Read more';

					open = false;
				} else {
					content.style.maxHeight = `${contentFull.clientHeight}px`;
					e.target.innerText = 'Read less';

					open = true;
				}
			});
		}
	}, []);

	const showSuccess = () => {
		toast.info('Subscribed successfully', {
			position: 'top-center',
			// autoClose: 5000,
			hideProgressBar: true,
			// closeOnClick: true,
			// pauseOnHover: true,
			// draggable: true,
			// progress: undefined,
			// theme: 'colored',
			theme: 'dark',
		});
		setEmail(' ');
	};
	const showError = (message) => {
		toast.update(message, {
			position: 'top-center',
			// autoClose: 5000,
			hideProgressBar: true,
			// closeOnClick: true,
			// pauseOnHover: true,
			// draggable: true,
			// progress: undefined,
			// theme: 'colored',
			theme: 'dark',
		});
		setEmail(' ');
	};

	const subscriber = async (email) => {
		const payload = {
			method: 'POST',
			headers: {
				'Content-Type': 'application/json',
			},
			body: JSON.stringify({
				email: email,
			}),
		};
		try {
			const response = await fetch(
				'https://intrasettle-server.web.app/subscriber',
				payload
			);
			const response_1 = await response.json();
			showSuccess();
			// alert("success");
			console.log('SuccessDB:', response_1);
		} catch (error) {
			console.log('Error:', error);
			showError('You have already subscribed');
		}
	};

	const sendEmail = new SendEmail();
	const sendSubscriber = async () => {
		try {
			showSuccess();
			await subscriber(email);
			await sendEmail.sendSubscriber(email);
		} catch (error) {
			showError('Something went wrong');
		}
	};

	const onSubmit = async (event) => {
		event.preventDefault();
		// alert('Subscribed successfully');
		sendSubscriber();
		setEmail(' ');
	};
	return (
		<div>
			<section class="body-font pt-12">
				<div class="container mx-auto flex px-5 py-24 md:flex-row flex-col items-center">
					{HeroData.map((hero) => {
						return (
							<div
								key={hero.id}
								class="lg:flex-grow md:w-1/2 lg:pr-24 md:pr-16 flex flex-col md:items-start md:text-left mb-16 md:mb-0 items-center text-center"
							>
								<h1 class="title-font sm:text-7xl text-3xl mb-12 font-bold">
									{hero.title1} <br />
									<span class="word-home">{hero.title2}</span>
								</h1>
								<div class="content">
									<div class="content-inner">
										<div class="content-full">
											<p class="text-xl text-gray-300 leading-relaxed">
												{hero.description}
											</p>
										</div>
									</div>
									<span class="read-more underline text-blue-500">
										Read more
									</span>
								</div>

								<form onSubmit={onSubmit}>
									<div class="flex w-full md:justify-start justify-center items-end">
										<div class="mr-4 md:w-full lg:w-full xl:w-1/2 w-2/4">
											<input
												type="email"
												required
												id="hero-field"
												value={email}
												onChange={(e) => setEmail(e.target.value)}
												placeholder="your email"
												name="hero-field"
												class="w-full bg-gray-800 rounded border bg-opacity-50 border-gray-300 focus:ring-2 focus:ring-blue-200 focus:bg-transparent focus:border-blue-500 text-base outline-none text-white py-1 px-3 leading-8 transition-colors duration-200 ease-in-out"
											/>
										</div>
										{/* <div className="w-6rem  text-white font-bold flex align-items-center justify-content-center   mr-3"> */}
										<ToastContainer />
										<subscribe>
											<button class="subscribe text-white cbdc-bg-blue border-0 py-2 px-6 focus:outline-none rounded text-lg">
												Subscribe
											</button>
										</subscribe>
										{/* </div> */}
									</div>
								</form>
								{/* <div>
                  <p class="title-font text-lg lg:w-3/4 cbdc-text-green mt-4">
                    {hero.detail}
                  </p>
                </div> */}
							</div>
						);
					})}

					<div class="lg:max-w-lg lg:w-full md:w-1/2 w-5/6">
						<img
							class="object-cover object-center rounded ease-in-out transition duration-500 globe-mobile"
							alt="hero"
							src={'static/img/globe-alternative.png'}
						/>
						<div class="globe-interactive">
							<GlobeComponent />
							<p class="text-center ml-20 cbdc-text-green font-bold italic">
								Drag and hover over a country to see their CBDC status
							</p>
						</div>
					</div>
				</div>
			</section>
		</div>
	);
};

export { Hero };
