import { Header } from './Header/Header';
import Footer from './Footer';
import BlogPage from './Blog/Blog';

export const Blogs = () => {
  return (
    <div>
      <Header />
      <BlogPage />
      <Footer />
    </div>
  );
};
