import React from 'react';

const Associates = () => {
	return (
		<div>
			<section class="text-gray-400 body-font lg:pt-24 lg:mt-8">
				<div class="container lg:px-28 px-12 py-24 mx-auto">
					<div class="mb-20 text-center">
						<span class="lg:text-3xl text-xl font-bold cbdc-text-blue">
							Associates
						</span>
					</div>
					<div class="flex flex-wrap -m-4">
						<div class="xl:w-1/4 md:w-1/2 sm:w-1/2 w-1/2 p-2">
							<div class="col-span-1 flex justify-center items-center py-8 rounded-lg bg-gray-300">
								<div class="lg:h-28 flex items-center">
									<img
										class="lg:h-32 sm:h-20 h-20"
										src={'static/img/associates/bsv-blockchain.png'}
										alt="BSV Association"
									/>
								</div>
							</div>
						</div>
						<div class="xl:w-1/4 md:w-1/2 sm:w-1/2 w-1/2 p-2">
							<div class="col-span-1 flex justify-center items-center py-8 rounded-lg bg-gray-300">
								<div class="lg:h-28 flex items-center">
									<img
										class="lg:h-32 sm:h-20 h-20"
										src={'static/img/associates/BlockDojo_new_logo.png'}
										alt="Satoshi Block Dojo"
									/>
								</div>
							</div>
						</div>
						<div class="xl:w-1/4 md:w-1/2 sm:w-1/2 w-1/2 p-2">
							<div class="col-span-1 flex justify-center items-center py-8 rounded-lg bg-gray-300">
								<div class="lg:h-28 flex items-center">
									<img
										class="lg:h-36 sm:h-20 h-20"
										src={'static/img/associates/chainscript_dev_frame.png'}
										alt="ChainScript"
									/>
								</div>
							</div>
						</div>
						<div class="xl:w-1/4 md:w-1/2 sm:w-1/2 w-1/2 p-2">
							<div class="col-span-1 flex justify-center items-center py-8 rounded-lg bg-gray-300">
								<div class="lg:h-28 flex items-center">
									<img
										class="lg:h-24 sm:h-18 h-18"
										src={'static/img/associates/elas_trans.png'}
										alt="Elas"
									/>
								</div>
							</div>
						</div>
					</div>
				</div>
			</section>
		</div>
	);
};

export default Associates;
