import React from 'react';

const VisionMission = () => {
  return (
    <div>
      <section class="body-font">
        <div class="container px-5 pt-36 mx-auto">
          {/* <div class="flex flex-wrap -m-4 border-gray-500 border-2 rounded-lg"> */}
          <div class="flex flex-wrap -m-4">
            <div class="p-4 lg:w-2/5 w-full">
              <div class="h-full p-8 rounded-lg">
                <p class="cbdc-text-green font-bold text-lg uppercase">
                  What we aspire to be
                </p>
                <p class="mb-8 text-white font-bold text-6xl uppercase">
                  Vision
                </p>
                <p class="text-xl mb-6 text-gray-400 font-medium">
                  Our vision is to fundamentally transform the landscape of
                  global payments by harnessing the power of central bank
                  digital currencies (CBDCs) and blockchain technology. We
                  strive to revolutionize the way international transactions are
                  conducted, bridging currencies and unifying payments. By
                  creating a seamless and efficient financial market
                  infrastructure, we aim to enable real-time, secure, and
                  cost-effective cross-border settlements, unlocking new
                  possibilities for individuals, businesses, and economies
                  worldwide.
                </p>
              </div>
            </div>

            <div className="p-4 lg:w-1/5 w-full flex justify-center">
              <lottie-player
                src="https://lottie.host/f80b674d-d97c-4599-9788-af7fe7809583/ZCwJuuKmcc.json"
                background="transparent"
                speed="1"
                class="lg:w-full md:w-1/2 w-2/3"
                loop
                autoplay
              ></lottie-player>
            </div>

            <div class="p-4 lg:w-2/5 w-full">
              <div class="h-full p-8 rounded-lg">
                <p class="cbdc-text-blue font-bold text-lg uppercase">
                  What we are, what we do
                </p>
                <p class="mb-8 text-white font-bold text-6xl uppercase">
                  Mission
                </p>
                <p class="text-xl mb-6 text-gray-400 font-medium">
                  Our mission is to empower financial institutions and market
                  participants with a cutting-edge multi-CBDC platform that
                  facilitates direct, secure, and affordable cross-border
                  settlements. By leveraging the advantages of CBDCs and
                  blockchain, we aim to reduce the complexity and cost
                  associated with cross-border transactions. We are committed to
                  enabling the finality of settlements using central bank money,
                  ensuring greater transparency, enhanced supervision, and
                  improved financial inclusion.
                </p>
              </div>
            </div>
          </div>
        </div>
      </section>
    </div>
  );
};

export default VisionMission;
