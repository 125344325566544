import React, { useEffect, useState } from 'react';
import axios from 'axios';
import { useParams } from 'react-router';
import { TailSpin } from 'react-loader-spinner';

import { WhatsappShareButton, WhatsappIcon } from 'react-share';
import { TwitterShareButton, TwitterIcon } from 'react-share';
import { FacebookShareButton, FacebookIcon } from 'react-share';
import { LinkedinShareButton, LinkedinIcon } from 'react-share';
import { TelegramShareButton, TelegramIcon } from 'react-share';

import { HeaderBlog } from './../HeaderBlog/HeaderBlog';
import FooterBlog from './../FooterBlog';
import EmptyList from '../common/EmptyList';
import './BlogPage.css';

function Blog({ match }) {
	const { slug } = useParams();
	const [blog, setBlog] = useState(null);

	const url = window.location.href;

	useEffect(() => {
		const fetchData = async () => {
			try {
				// Fetch data from the API
				const response = await axios.get(
					'https://intrasettle-server.web.app/blog'
				);
				const blogsArray = Object.values(response.data);
				const dataSet = blogsArray[2].data;
				// Find the blog with the given slug
				const filteredBlog = dataSet.find((blog) => blog.slug === slug);
				// Set the found blog in the state
				setBlog(filteredBlog);
			} catch (error) {
				console.log(error);
			}
		};
		fetchData();
	}, []); // eslint-disable-line react-hooks/exhaustive-deps

	if (!blog)
		return (
			<div class="flex justify-center items-center h-screen">
				<div class="spinner-container">
					<TailSpin
						height="80"
						width="80"
						color="#1177e1"
						ariaLabel="tail-spin-loading"
						radius="1"
						wrapperStyle={{}}
						wrapperClass=""
						visible={true}
					/>
				</div>
			</div>
		);

	return (
		<>
			<HeaderBlog />
			<div className="py-12"></div>
			{blog ? (
				<div className="blog-wrap py-8 px-8">
					{(() => {
						if (blog.mainVideo) {
							return (
								<iframe
									class="my-20 mx-auto youtube-video-player"
									src={blog.mainVideo}
									title="YouTube video player"
									frameBorder="0"
									allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share"
									allowFullScreen
								></iframe>
							);
						} else {
							return (
								<div class="rounded-lg overflow-hidden">
									<img
										alt="content"
										class="object-cover object-center h-full w-full"
										src={blog.cover}
									/>
								</div>
							);
						}
					})()}

					<h4 class="title-font text-xl uppercase font-bold cbdc-text-blue mt-2">
						#{blog.category}
					</h4>

					<h1 class="title-font text-4xl lg:text-6xl font-semibold leading-12 text-gray-300 my-12">
						{blog.title}
					</h1>

					<h4 class="title-font text-2xl font-medium text-gray-400 mt-2 mb-12">
						{blog.date}
					</h4>

					<div class="h-1 px-auto cbdc-bg-blue rounded-lg opacity-80"></div>

					<div class="py-12">
						{(() => {
							if (blog.description0) {
								return (
									<p class="blod-description font-medium leading-normal text-gray-300 mt-8">
										{blog.description0}
									</p>
								);
							} else {
								return <p></p>;
							}
						})()}
						{(() => {
							if (blog.description1) {
								return (
									<p class="blod-description font-medium leading-normal text-gray-300 mt-8">
										{blog.description1}
									</p>
								);
							} else {
								return <p></p>;
							}
						})()}
						{(() => {
							if (blog.description2) {
								return (
									<p class="blod-description font-medium leading-normal text-gray-300 mt-8">
										{blog.description2}
									</p>
								);
							} else {
								return <p></p>;
							}
						})()}
						{(() => {
							if (blog.description3) {
								return (
									<p class="blod-description font-medium leading-normal text-gray-300 mt-8">
										{blog.description3}
									</p>
								);
							} else {
								return <p></p>;
							}
						})()}
						{(() => {
							if (blog.description4) {
								return (
									<p class="blod-description font-medium leading-normal text-gray-300 mt-8">
										{blog.description4}
									</p>
								);
							} else {
								return <p></p>;
							}
						})()}
						{(() => {
							if (blog.description5) {
								return (
									<p class="blod-description font-medium leading-normal text-gray-300 mt-8">
										{blog.description5}
									</p>
								);
							} else {
								return <p></p>;
							}
						})()}
						{(() => {
							if (blog.description6) {
								return (
									<p class="blod-description font-medium leading-normal text-gray-300 mt-8">
										{blog.description6}
									</p>
								);
							} else {
								return <p></p>;
							}
						})()}
						{(() => {
							if (blog.description7) {
								return (
									<p class="blod-description font-medium leading-normal text-gray-300 mt-8">
										{blog.description7}
									</p>
								);
							} else {
								return <p></p>;
							}
						})()}
						{(() => {
							if (blog.description8) {
								return (
									<p class="blod-description font-medium leading-normal text-gray-300 mt-8">
										{blog.description8}
									</p>
								);
							} else {
								return <p></p>;
							}
						})()}
						{(() => {
							if (blog.description9) {
								return (
									<p class="blod-description font-medium leading-normal text-gray-300 mt-8">
										{blog.description9}
									</p>
								);
							} else {
								return <p></p>;
							}
						})()}

						{(() => {
							if (blog.video1) {
								return (
									<iframe
										class="my-20 mx-auto youtube-video-player"
										src={blog.video1}
										title="YouTube video player"
										frameBorder="0"
										allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share"
										allowFullScreen
									></iframe>
								);
							} else {
								return <p></p>;
							}
						})()}

						{(() => {
							if (blog.description10) {
								return (
									<p class="blod-description font-medium leading-normal text-gray-300 mt-8">
										{blog.description10}
									</p>
								);
							} else {
								return <p></p>;
							}
						})()}
						{(() => {
							if (blog.description11) {
								return (
									<p class="blod-description font-medium leading-normal text-gray-300 mt-8">
										{blog.description11}
									</p>
								);
							} else {
								return <p></p>;
							}
						})()}
						{(() => {
							if (blog.description12) {
								return (
									<p class="blod-description font-medium leading-normal text-gray-300 mt-8">
										{blog.description12}
									</p>
								);
							} else {
								return <p></p>;
							}
						})()}
						{(() => {
							if (blog.description13) {
								return (
									<p class="blod-description font-medium leading-normal text-gray-300 mt-8">
										{blog.description13}
									</p>
								);
							} else {
								return <p></p>;
							}
						})()}
						{(() => {
							if (blog.description14) {
								return (
									<p class="blod-description font-medium leading-normal text-gray-300 mt-8">
										{blog.description14}
									</p>
								);
							} else {
								return <p></p>;
							}
						})()}
						{(() => {
							if (blog.description15) {
								return (
									<p class="blod-description font-medium leading-normal text-gray-300 mt-8">
										{blog.description15}
									</p>
								);
							} else {
								return <p></p>;
							}
						})()}
						{(() => {
							if (blog.description16) {
								return (
									<p class="blod-description font-medium leading-normal text-gray-300 mt-8">
										{blog.description16}
									</p>
								);
							} else {
								return <p></p>;
							}
						})()}
						{(() => {
							if (blog.description17) {
								return (
									<p class="blod-description font-medium leading-normal text-gray-300 mt-8">
										{blog.description17}
									</p>
								);
							} else {
								return <p></p>;
							}
						})()}
						{(() => {
							if (blog.description18) {
								return (
									<p class="blod-description font-medium leading-normal text-gray-300 mt-8">
										{blog.description18}
									</p>
								);
							} else {
								return <p></p>;
							}
						})()}
						{(() => {
							if (blog.description19) {
								return (
									<p class="blod-description font-medium leading-normal text-gray-300 mt-8">
										{blog.description19}
									</p>
								);
							} else {
								return <p></p>;
							}
						})()}
						{(() => {
							if (blog.description20) {
								return (
									<p class="blod-description font-medium leading-normal text-gray-300 mt-8">
										{blog.description20}
									</p>
								);
							} else {
								return <p></p>;
							}
						})()}
						{(() => {
							if (blog.image1) {
								return (
									<div class="rounded-lg overflow-hidden">
										<img
											alt="content"
											class="object-cover object-center py-8"
											src={blog.image1}
										/>
									</div>
								);
							} else {
								return <p></p>;
							}
						})()}
						{(() => {
							if (blog.description21) {
								return (
									<p class="blod-description font-medium leading-normal text-gray-300 mt-8">
										{blog.description21}
									</p>
								);
							} else {
								return <p></p>;
							}
						})()}
						{(() => {
							if (blog.description22) {
								return (
									<p class="blod-description font-medium leading-normal text-gray-300 mt-8">
										{blog.description22}
									</p>
								);
							} else {
								return <p></p>;
							}
						})()}
						{(() => {
							if (blog.description23) {
								return (
									<p class="blod-description font-medium leading-normal text-gray-300 mt-8">
										{blog.description23}
									</p>
								);
							} else {
								return <p></p>;
							}
						})()}
						{(() => {
							if (blog.description24) {
								return (
									<p class="blod-description font-medium leading-normal text-gray-300 mt-8">
										{blog.description24}
									</p>
								);
							} else {
								return <p></p>;
							}
						})()}
						{(() => {
							if (blog.description25) {
								return (
									<p class="blod-description font-medium leading-normal text-gray-300 mt-8">
										{blog.description25}
									</p>
								);
							} else {
								return <p></p>;
							}
						})()}
						{(() => {
							if (blog.description26) {
								return (
									<p class="blod-description font-medium leading-normal text-gray-300 mt-8">
										{blog.description26}
									</p>
								);
							} else {
								return <p></p>;
							}
						})()}
						{(() => {
							if (blog.description27) {
								return (
									<p class="blod-description font-medium leading-normal text-gray-300 mt-8">
										{blog.description27}
									</p>
								);
							} else {
								return <p></p>;
							}
						})()}
						{(() => {
							if (blog.description28) {
								return (
									<p class="blod-description font-medium leading-normal text-gray-300 mt-8">
										{blog.description28}
									</p>
								);
							} else {
								return <p></p>;
							}
						})()}
						{(() => {
							if (blog.description29) {
								return (
									<p class="blod-description font-medium leading-normal text-gray-300 mt-8">
										{blog.description29}
									</p>
								);
							} else {
								return <p></p>;
							}
						})()}
						{(() => {
							if (blog.description30) {
								return (
									<p class="blod-description font-medium leading-normal text-gray-300 mt-8">
										{blog.description30}
									</p>
								);
							} else {
								return <p></p>;
							}
						})()}
						{(() => {
							if (blog.description31) {
								return (
									<p class="blod-description font-medium leading-normal text-gray-300 mt-8">
										{blog.description31}
									</p>
								);
							} else {
								return <p></p>;
							}
						})()}
						{(() => {
							if (blog.description32) {
								return (
									<p class="blod-description font-medium leading-normal text-gray-300 mt-8">
										{blog.description32}
									</p>
								);
							} else {
								return <p></p>;
							}
						})()}
						{(() => {
							if (blog.description33) {
								return (
									<p class="blod-description font-medium leading-normal text-gray-300 mt-8">
										{blog.description33}
									</p>
								);
							} else {
								return <p></p>;
							}
						})()}
						{(() => {
							if (blog.description34) {
								return (
									<p class="blod-description font-medium leading-normal text-gray-300 mt-8">
										{blog.description34}
									</p>
								);
							} else {
								return <p></p>;
							}
						})()}
						{(() => {
							if (blog.description35) {
								return (
									<p class="blod-description font-medium leading-normal text-gray-300 mt-8">
										{blog.description35}
									</p>
								);
							} else {
								return <p></p>;
							}
						})()}
						{(() => {
							if (blog.description36) {
								return (
									<p class="blod-description font-medium leading-normal text-gray-300 mt-8">
										{blog.description36}
									</p>
								);
							} else {
								return <p></p>;
							}
						})()}
						{(() => {
							if (blog.description37) {
								return (
									<p class="blod-description font-medium leading-normal text-gray-300 mt-8">
										{blog.description37}
									</p>
								);
							} else {
								return <p></p>;
							}
						})()}
						{(() => {
							if (blog.description38) {
								return (
									<p class="blod-description font-medium leading-normal text-gray-300 mt-8">
										{blog.description38}
									</p>
								);
							} else {
								return <p></p>;
							}
						})()}
						{(() => {
							if (blog.description39) {
								return (
									<p class="blod-description font-medium leading-normal text-gray-300 mt-8">
										{blog.description39}
									</p>
								);
							} else {
								return <p></p>;
							}
						})()}
						{(() => {
							if (blog.description40) {
								return (
									<p class="blod-description font-medium leading-normal text-gray-300 mt-8">
										{blog.description40}
									</p>
								);
							} else {
								return <p></p>;
							}
						})()}
						{(() => {
							if (blog.description41) {
								return (
									<p class="blod-description font-medium leading-normal text-gray-300 mt-8">
										{blog.description41}
									</p>
								);
							} else {
								return <p></p>;
							}
						})()}
						{(() => {
							if (blog.description42) {
								return (
									<p class="blod-description font-medium leading-normal text-gray-300 mt-8">
										{blog.description42}
									</p>
								);
							} else {
								return <p></p>;
							}
						})()}
						{(() => {
							if (blog.description43) {
								return (
									<p class="blod-description font-medium leading-normal text-gray-300 mt-8">
										{blog.description43}
									</p>
								);
							} else {
								return <p></p>;
							}
						})()}
						{(() => {
							if (blog.description44) {
								return (
									<p class="blod-description font-medium leading-normal text-gray-300 mt-8">
										{blog.description44}
									</p>
								);
							} else {
								return <p></p>;
							}
						})()}
						{(() => {
							if (blog.description45) {
								return (
									<p class="blod-description font-medium leading-normal text-gray-300 mt-8">
										{blog.description45}
									</p>
								);
							} else {
								return <p></p>;
							}
						})()}
						{(() => {
							if (blog.description46) {
								return (
									<p class="blod-description font-medium leading-normal text-gray-300 mt-8">
										{blog.description46}
									</p>
								);
							} else {
								return <p></p>;
							}
						})()}

						{(() => {
							if (blog.image2) {
								return (
									<div class="rounded-lg overflow-hidden">
										<img
											alt="content"
											class="object-cover object-center py-8"
											src={blog.image2}
										/>
									</div>
								);
							} else {
								return <p></p>;
							}
						})()}

						{(() => {
							if (blog.video2) {
								return (
									<iframe
										class="my-20 mx-auto youtube-video-player"
										src={blog.video2}
										title="YouTube video player"
										frameBorder="0"
										allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share"
										allowFullScreen
									></iframe>
								);
							} else {
								return <p></p>;
							}
						})()}

						{/* Link section */}
						{(() => {
							if (blog.linkDescription) {
								return (
									<p class="text-2xl font-medium leading-normal text-gray-400 mt-8">
										{blog.linkDescription}
									</p>
								);
							} else {
								return <p></p>;
							}
						})()}
						{(() => {
							if (blog.linkAddress1) {
								return (
									<div>
										<a
											target="_blank"
											rel="noopener noreferrer"
											class="cbdc-text-blue hover:underline blog-description font-bold leading-normal mt-8"
											href={blog.linkAddress1}
										>
											{blog.linkTitle1}
										</a>
									</div>
								);
							} else {
								return <p></p>;
							}
						})()}
						{(() => {
							if (blog.linkAddress2) {
								return (
									<div>
										<a
											target="_blank"
											rel="noopener noreferrer"
											class="cbdc-text-blue hover:underline blog-description font-bold leading-normal mt-8"
											href={blog.linkAddress2}
										>
											{blog.linkTitle2}
										</a>
									</div>
								);
							} else {
								return <p></p>;
							}
						})()}
						{(() => {
							if (blog.linkAddress3) {
								return (
									<div>
										<a
											target="_blank"
											rel="noopener noreferrer"
											class="cbdc-text-blue hover:underline blog-description font-bold leading-normal mt-8"
											href={blog.linkAddress3}
										>
											{blog.linkTitle3}
										</a>
									</div>
								);
							} else {
								return <p></p>;
							}
						})()}

						<div class="my-4">
							{blog.hashtag.map((hashtag, i) => (
								<a
									key={i}
									target="_blank"
									rel="noopener noreferrer"
									class="cbdc-text-blue hover:underline blog-description font-bold leading-normal mr-2"
									href={hashtag.link}
								>
									#{hashtag.name}
								</a>
							))}
						</div>
					</div>

					<div className="icon-bar">
						<>
							<div>
								<FacebookShareButton
									url={url}
									src={blog.cover}
									title={blog.title}
									quote={'subscribe share post'}
									hashtag="#chainscript"
								>
									<FacebookIcon
										className="icon-individual-top icon-margin"
										logoFillColor="white"
										size="45"
									></FacebookIcon>
								</FacebookShareButton>
							</div>
							<div>
								<LinkedinShareButton
									url={url}
									title={blog.title}
									summary={blog.description}
									src={blog.cover}
									quote={'subscribe share post'}
									hashtag="#chainscript"
								>
									<LinkedinIcon
										logoFillColor="white"
										className="icon-margin"
										size="45"
									></LinkedinIcon>
								</LinkedinShareButton>
							</div>
							<div>
								<TwitterShareButton
									url={url}
									src={blog.cover}
									title={blog.title}
									quote={'subscribe share post'}
									hashtag="#chainscript"
								>
									<TwitterIcon
										logoFillColor="white"
										className="icon-margin"
										size="45"
									></TwitterIcon>
								</TwitterShareButton>
							</div>
							<div>
								<WhatsappShareButton
									src={blog.cover}
									image={blog.cover}
									title={blog.title}
									separator=" - "
									url={url}
								>
									<WhatsappIcon
										logoFillColor="white"
										className="icon-margin"
										size="45"
									></WhatsappIcon>
								</WhatsappShareButton>
							</div>
							<div>
								<TelegramShareButton url={url} src={blog.cover}>
									<TelegramIcon
										logoFillColor="white"
										className="icon-individual-bottom"
										size="45"
									></TelegramIcon>
								</TelegramShareButton>
							</div>
						</>
					</div>
				</div>
			) : (
				<EmptyList />
			)}
			<FooterBlog />
		</>
	);
}

export { Blog };
