import React, { useState } from 'react';
import { Link } from 'react-router-dom';
import './Header.css';

function Header() {
	const [navbar, setNavbar] = useState(false);

	return (
		<nav className="w-full fixed header-main flex justify-center">
			<div className=" container justify-between px-4 md:items-center md:flex">
				<div>
					<div className="flex items-center justify-between py-3 md:py-5 md:block">
						<Link
							to="/"
							class="flex title-font font-medium items-center mb-4 md:mb-0"
						>
							<img
								src={'static/img/intrasettle_white.png'}
								alt="intrasettle"
								class="h-14"
							></img>
						</Link>
						<div className="md:hidden">
							<button
								className="p-2 text-gray-300 rounded-md outline-none focus:border-gray-400 focus:border"
								onClick={() => setNavbar(!navbar)}
							>
								{navbar ? (
									<svg
										xmlns="http://www.w3.org/2000/svg"
										className="w-6 h-6"
										viewBox="0 0 20 20"
										fill="currentColor"
									>
										<path
											fillRule="evenodd"
											d="M4.293 4.293a1 1 0 011.414 0L10 8.586l4.293-4.293a1 1 0 111.414 1.414L11.414 10l4.293 4.293a1 1 0 01-1.414 1.414L10 11.414l-4.293 4.293a1 1 0 01-1.414-1.414L8.586 10 4.293 5.707a1 1 0 010-1.414z"
											clipRule="evenodd"
										/>
									</svg>
								) : (
									<svg
										xmlns="http://www.w3.org/2000/svg"
										className="w-6 h-6"
										fill="none"
										viewBox="0 0 24 24"
										stroke="currentColor"
										strokeWidth={2}
									>
										<path
											strokeLinecap="round"
											strokeLinejoin="round"
											d="M4 6h16M4 12h16M4 18h16"
										/>
									</svg>
								)}
							</button>
						</div>
					</div>
				</div>

				<div>
					<div
						className={`flex-1 justify-self-center pb-3 mt-8 md:block md:pb-0 md:mt-0 ${
							navbar ? 'block' : 'hidden'
						}`}
					>
						<ul className="items-center justify-center space-y-8 md:flex md:space-x-6 md:space-y-0">
							<li className="text-white hover:text-blue-600">
								<Link to="/">Home</Link>
							</li>
							<li className="text-white hover:text-blue-600">
								<Link to="/blog">Blog</Link>
							</li>
							<li className="text-white hover:text-blue-600">
								<Link to="/contact">Join our Team</Link>
							</li>
							<li className="">
								<Link
									to="/invest"
									class="inline-flex items-center bg-blue-500 border-0 py-1 px-3 rounded text-base mt-4 md:mt-0"
								>
									Invest
									<svg
										fill="none"
										stroke="currentColor"
										stroke-linecap="round"
										stroke-linejoin="round"
										stroke-width="2"
										class="w-4 h-4 ml-1"
										viewBox="0 0 24 24"
									>
										<path d="M5 12h14M12 5l7 7-7 7"></path>
									</svg>
								</Link>
							</li>
						</ul>
					</div>
				</div>
			</div>
		</nav>
	);
}

export { Header };
