import React from 'react';
import './Team.css';

const Team = () => {
	return (
		<div>
			<section id="team" class="text-gray-400 body-font lg:pt-12 lg:mt-16">
				<div class="container px-5 py-24 mx-auto">
					<div class="mb-20 text-center">
						<span class="text-3xl font-bold cbdc-text-blue">Team</span>
					</div>
					<div class="flex flex-wrap -m-4 justify-center">
						<div class="lg:w-1/3 md:w-1/2 p-4 flex justify-center">
							<div class="flex relative">
								<div class="absolute inset-0 w-80 h-96 profile-background rounded-lg shadow-md ">
									<img
										src={'static/img/profile/Kumaraguru.png'}
										alt="profile"
										className="rounded-lg pt-6 px-6"
									/>
									<div class="flex items-center justify-between p-5">
										<div class="">
											<h5 class="mb-1 text-lg font-bold tracking-tight text-gray-300">
												Kumaraguru Ramanujam
											</h5>
											<p class="mb-3 font-normal text-gray-400">
												Chief Executive Officer
											</p>
										</div>
										<a
											target="_blank"
											rel="noreferrer"
											href="https://www.linkedin.com/in/kumaragururamanujam/"
										>
											<svg
												xmlns="http://www.w3.org/2000/svg"
												class="w-8 h-8 rounded-lg linkedin-logo"
												fill="#fafafa"
												viewBox="0 0 512 512"
											>
												<title>Kumaraguru Ramanujam</title>
												<path d="M444.17 32H70.28C49.85 32 32 46.7 32 66.89v374.72C32 461.91 49.85 480 70.28 480h373.78c20.54 0 35.94-18.21 35.94-38.39V66.89C480.12 46.7 464.6 32 444.17 32zm-273.3 373.43h-64.18V205.88h64.18zM141 175.54h-.46c-20.54 0-33.84-15.29-33.84-34.43 0-19.49 13.65-34.42 34.65-34.42s33.85 14.82 34.31 34.42c-.01 19.14-13.31 34.43-34.66 34.43zm264.43 229.89h-64.18V296.32c0-26.14-9.34-44-32.56-44-17.74 0-28.24 12-32.91 23.69-1.75 4.2-2.22 9.92-2.22 15.76v113.66h-64.18V205.88h64.18v27.77c9.34-13.3 23.93-32.44 57.88-32.44 42.13 0 74 27.77 74 87.64z" />
											</svg>
										</a>
									</div>
								</div>
								<div class="relative z-10 w-80 h-96 rounded-lg profile-card">
									<p class="text-gray-100 text-sm leading-relaxed p-4">
										Kumaraguru is a computer science engineer with over 15 years
										experience in managing businesses driven by technology. With
										vast experience in product development and sales. He has
										held leadership roles in UAE, Singapore, Qatar, Saudi &
										Indian companies including advising government agencies on
										the impact of new technologies. He has a passion for
										bringing efficiencies to technology-driven solutions. He is
										the BSV Blockchain Ambassador for India helping build a
										developer network for the advancement of blockchain.
									</p>
									<a
										target="_blank"
										rel="noreferrer"
										href="https://www.linkedin.com/in/kumaragururamanujam/"
									>
										<svg
											xmlns="http://www.w3.org/2000/svg"
											class="w-8 h-8 rounded-lg linkedin-logo"
											fill="#fafafa"
											viewBox="0 0 512 512"
										>
											<title>Kumaraguru Ramanujam</title>
											<path d="M444.17 32H70.28C49.85 32 32 46.7 32 66.89v374.72C32 461.91 49.85 480 70.28 480h373.78c20.54 0 35.94-18.21 35.94-38.39V66.89C480.12 46.7 464.6 32 444.17 32zm-273.3 373.43h-64.18V205.88h64.18zM141 175.54h-.46c-20.54 0-33.84-15.29-33.84-34.43 0-19.49 13.65-34.42 34.65-34.42s33.85 14.82 34.31 34.42c-.01 19.14-13.31 34.43-34.66 34.43zm264.43 229.89h-64.18V296.32c0-26.14-9.34-44-32.56-44-17.74 0-28.24 12-32.91 23.69-1.75 4.2-2.22 9.92-2.22 15.76v113.66h-64.18V205.88h64.18v27.77c9.34-13.3 23.93-32.44 57.88-32.44 42.13 0 74 27.77 74 87.64z" />
										</svg>
									</a>
								</div>
							</div>
						</div>

						<div class="lg:w-1/3 md:w-1/2 p-4 flex justify-center">
							<div class="flex relative">
								<div class="absolute inset-0 w-80 h-96 profile-background rounded-lg shadow-md ">
									<img
										src={'static/img/profile/gowtham-dp.png'}
										alt="profile"
										className="rounded-lg pt-6 px-6"
									/>
									<div class="flex items-center justify-between p-5">
										<div class="">
											<h5 class="mb-1 text-lg font-bold tracking-tight text-gray-300">
												Gowtham Selvam
											</h5>
											<p class="mb-3 font-normal text-gray-400">
												Project Manager
											</p>
										</div>
										<a
											target="_blank"
											rel="noreferrer"
											href="https://www.linkedin.com/in/gowtham-selvam/"
										>
											<svg
												xmlns="http://www.w3.org/2000/svg"
												class="w-8 h-8 rounded-lg linkedin-logo"
												fill="#fafafa"
												viewBox="0 0 512 512"
											>
												<title>Gowtham Selvam</title>
												<path d="M444.17 32H70.28C49.85 32 32 46.7 32 66.89v374.72C32 461.91 49.85 480 70.28 480h373.78c20.54 0 35.94-18.21 35.94-38.39V66.89C480.12 46.7 464.6 32 444.17 32zm-273.3 373.43h-64.18V205.88h64.18zM141 175.54h-.46c-20.54 0-33.84-15.29-33.84-34.43 0-19.49 13.65-34.42 34.65-34.42s33.85 14.82 34.31 34.42c-.01 19.14-13.31 34.43-34.66 34.43zm264.43 229.89h-64.18V296.32c0-26.14-9.34-44-32.56-44-17.74 0-28.24 12-32.91 23.69-1.75 4.2-2.22 9.92-2.22 15.76v113.66h-64.18V205.88h64.18v27.77c9.34-13.3 23.93-32.44 57.88-32.44 42.13 0 74 27.77 74 87.64z" />
											</svg>
										</a>
									</div>
								</div>
								<div class="relative z-10 w-80 h-96 rounded-lg profile-card">
									<p class="text-gray-100 text-sm leading-relaxed p-4">
										Gowtham holds a Master’s degree in structural engineering
										with over three years of experience in software development.
										His passion for blockchain led him to become a full-stack
										developer. He has managed several front-end and back-end
										development projects with tight deadlines. He holds multiple
										certifications in blockchain development, Node.js,
										JavaScript, MongoDB, SQL and other front-end frameworks.
									</p>
									<a
										target="_blank"
										rel="noreferrer"
										href="https://www.linkedin.com/in/gowtham-selvam/"
									>
										<svg
											xmlns="http://www.w3.org/2000/svg"
											class="w-8 h-8 rounded-lg linkedin-logo"
											fill="#fafafa"
											viewBox="0 0 512 512"
										>
											<title>Gowtham Selvam</title>
											<path d="M444.17 32H70.28C49.85 32 32 46.7 32 66.89v374.72C32 461.91 49.85 480 70.28 480h373.78c20.54 0 35.94-18.21 35.94-38.39V66.89C480.12 46.7 464.6 32 444.17 32zm-273.3 373.43h-64.18V205.88h64.18zM141 175.54h-.46c-20.54 0-33.84-15.29-33.84-34.43 0-19.49 13.65-34.42 34.65-34.42s33.85 14.82 34.31 34.42c-.01 19.14-13.31 34.43-34.66 34.43zm264.43 229.89h-64.18V296.32c0-26.14-9.34-44-32.56-44-17.74 0-28.24 12-32.91 23.69-1.75 4.2-2.22 9.92-2.22 15.76v113.66h-64.18V205.88h64.18v27.77c9.34-13.3 23.93-32.44 57.88-32.44 42.13 0 74 27.77 74 87.64z" />
										</svg>
									</a>
								</div>
							</div>
						</div>
					</div>
				</div>
			</section>
		</div>
	);
};

export default Team;
