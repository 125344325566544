import React, { useEffect } from 'react';
import EventData from './events.json';
import './Events.css';

const Events = () => {
	useEffect(() => {
		const delay = 20000; //ms
		const slides = document.querySelector('.slides');
		const slidesCount = slides.childElementCount;
		const maxLeft = (slidesCount - 1) * 100 * -1;

		let current = 0;

		const changeSlide = (next = true) => {
			if (next) {
				current += current > maxLeft ? -100 : current * -1;
			} else {
				current = current < 0 ? current + 100 : maxLeft;
			}

			slides.style.left = current + '%';
		};

		let autoChange = setInterval(changeSlide, delay);
		const restart = function () {
			clearInterval(autoChange);
			autoChange = setInterval(changeSlide, delay);
		};

		// Controls
		document
			.querySelector('.next-slide')
			.addEventListener('click', function () {
				changeSlide();
				restart();
			});

		document
			.querySelector('.prev-slide')
			.addEventListener('click', function () {
				changeSlide(false);
				restart();
			});
	}, []);
	return (
		<div>
			<section class="body-font lg:pt-24 lg:mt-24">
				<div class="text-center">
					<span class="lg:text-3xl text-xl font-bold cbdc-text-blue">
						Interviews
					</span>
				</div>
				<div class="flex justify-center">
					<div class="carousel container">
						<div class="slides">
							{EventData.map((event) => {
								return (
									<div
										key={event.id}
										class="container mx-auto flex justify-around px-4 py-24 md:flex-row flex-col items-center slide"
									>
										<div class="lg:flex-grow md:w-1/2 lg:pr-24 md:pr-16 flex flex-col md:items-start md:text-left mb-4 md:mb-0 items-center text-center">
											<h1 class="title-font sm:text-4xl text-xl mb-4 font-medium text-white">
												{event.title}
											</h1>
											<p class="mb-4 text-gray-400 leading-relaxed">
												{event.description}
											</p>
										</div>
										<div class="lg:max-w-lg lg:w-full md:w-1/2 w-5/6 youtube-content">
											{(() => {
												if (event.video) {
													return (
														<iframe
															width="500"
															height="282"
															class="rounded-lg"
															src={event.video}
															title="YouTube video player"
															frameBorder="0"
															allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
															allowFullScreen
														></iframe>
													);
												} else {
													return (
														<a href={event.link}>
															<img
																src={event.image}
																alt="intrasettle"
																class="rounded-lg"
															/>
														</a>
													);
												}
											})()}
										</div>
									</div>
								);
							})}
							;
						</div>
						<div class="controls">
							<div class="control prev-slide">&#9668;</div>
							<div class="control next-slide">&#9658;</div>
						</div>
					</div>
				</div>
			</section>
		</div>
	);
};

export { Events };
